import { useCallback, useEffect, useState } from "react";

import { Table, Spinner } from "@atoms";
import {
  formatDateWithoutTime,
  formatToPercentage,
} from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";

const pipRatesColumns = [
  {
    id: "pipTransactionDate",
    header: "Transaction Date",
    cell: (tableRow) => {
      const { pipTransactionDate } = tableRow?.row?.original || {};
      return pipTransactionDate
        ? formatDateWithoutTime(pipTransactionDate)
        : "";
    },
  },
  {
    id: "pipCategory",
    header: "Category",
    accessorKey: "pipCategory",
  },
  {
    id: "pipWithdrawalRateType",
    header: "Withdrawal Rate Type",
    accessorKey: "pipWithdrawalRateType",
  },
  {
    id: "pipMinimumAge",
    header: "Minimum Age",
    alignRight: true,
    cell: (tableRow) => {
      const { pipMinimumAge } = tableRow?.row?.original || {};
      return <div className="text-right">{pipMinimumAge}</div>;
    },
  },
  {
    id: "pipMaximumAge",
    header: "Maximum Age",
    alignRight: true,
    cell: (tableRow) => {
      const { pipMaximumAge } = tableRow?.row?.original || {};
      return <div className="text-right">{pipMaximumAge}</div>;
    },
  },
  {
    id: "pipWithdrawalRateSingle",
    header: "Single Policy Rate",
    alignRight: true,
    cell: (tableRow) => {
      const { pipWithdrawalRateSingle } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {pipWithdrawalRateSingle
            ? formatToPercentage(pipWithdrawalRateSingle * 100)
            : ""}
        </div>
      );
    },
  },
  {
    id: "pipWithdrawalRateJoint",
    header: "Joint Policy Rate",
    cell: (tableRow) => {
      const { pipWithdrawalRateJoint } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {pipWithdrawalRateJoint
            ? formatToPercentage(pipWithdrawalRateJoint * 100)
            : ""}
        </div>
      );
    },
    alignRight: true,
  },
  {
    id: "lastUpdatedOn",
    header: "Last Updated",
    cell: (tableRow) => {
      const { lastUpdatedOn } = tableRow?.row?.original || {};
      return (
        <div>{lastUpdatedOn ? formatDateWithoutTime(lastUpdatedOn) : ""}</div>
      );
    },
  },
];

const PipRatesTable = ({ id }) => {
  const { get, isPending } = useApiFetch();
  const [accountPipRates, setAccountPipRates] = useState({});
  const { data: tableData = [] } = accountPipRates || {};

  const getAccountPipRates = useCallback(async () => {
    const data = await get(`reaccounts/pipRates/${id}`);
    setAccountPipRates(data);
  }, [id]);

  useEffect(() => {
    getAccountPipRates();
  }, [getAccountPipRates]);

  return (
    <Spinner requesting={isPending}>
      <Table
        columns={pipRatesColumns}
        data={tableData || []}
        noPadding
        hidePagination
        noBottomBorder
      />
    </Spinner>
  );
};

export default PipRatesTable;
