import { Container } from "reactstrap";

import ResubmitWFT1002Form from "./components/ResubmitWFT1002Form";

const ResubmitWFT1002 = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Resubmit Participant DOB Update</h3>

    <ResubmitWFT1002Form />
  </Container>
);

export default ResubmitWFT1002;
