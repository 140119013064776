import { useEffect, useMemo, useState } from "react";

import { Input } from "reactstrap";

import { DateRangePicker, TableSearchInput } from "@atoms";
import { progressStatusOptions } from "@config/conf";
import { filterOptions } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";

const commonClass = "btn-w text-light table__search table__search-input mb-0";

const WorkflowsTableSearch = ({ form, setSubmitSearch }) => {
  const { control, setValue, watch } = form;

  const { get } = useApiFetch();
  const [workflowTypes, setWorkflowTypes] = useState([]);

  const { searchWorkflow, workflowType, workflowStatus, fromDate, toDate } =
    watch();

  const shouldShowClearButton =
    searchWorkflow || workflowType || fromDate || toDate || workflowStatus;

  const getWorkflowTypes = async () => {
    const response = await get("workflowtypes/distinctwt");
    setWorkflowTypes(response?.data);
  };

  useEffect(() => {
    getWorkflowTypes();
  }, []);

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setValue("searchWorkflow", "");
    setValue("workflowType", "");
    setValue("workflowStatus", "");
    setValue("fromDate", null);
    setValue("toDate", null);
  };

  const workflowTypesOptions = filterOptions(
    workflowTypes,
    "workflowType",
    "workflowType"
  );

  const handleOnClick = () => {
    setSubmitSearch(true);
  };

  const { inputs, dateRangeFields, button } = useMemo(
    () => ({
      inputs: [
        {
          name: "searchWorkflow",
          placeholder: "WF#, RE Account Number or Policy Number",
          maxLength: 40,
          style: { maxWidth: "275px", width: "100%" },
        },
        {
          name: "workflowType",
          inputType: "select",
          placeholder: "Workflow Type",
          selectOptions: workflowTypesOptions,
          firstFieldEmpty: true,
        },
        {
          name: "workflowStatus",
          inputType: "select",
          placeholder: "Workflow Status",
          selectOptions: progressStatusOptions,
          firstFieldEmpty: true,
        },
      ],
      dateRangeFields: [
        {
          name: "fromDate",
          placeholder: "From Date (MM/DD/YYYY)",
        },
        {
          name: "toDate",
          placeholder: "To Date (MM/DD/YYYY)",
        },
      ],
      button: [
        {
          type: "submit",
          className: `btn-blue ${commonClass}`,
          value: "Filter",
          onClick: handleOnClick,
        },
        ...(shouldShowClearButton
          ? [
              {
                type: "reset",
                className: commonClass,
                value: "Clear",
                onClick: handleClear,
              },
            ]
          : []),
      ],
    }),
    [shouldShowClearButton, workflowTypesOptions]
  );

  return (
    <div className="table__search mb-0 flex align-items-center form-inline w-100">
      {inputs.map((item) => (
        <TableSearchInput control={control} {...item} key={item?.name} />
      ))}
      {dateRangeFields.map(({ name, placeholder }) => (
        <DateRangePicker
          key={name}
          name={name}
          control={control}
          placeholder={placeholder}
          datePickerVariant="table"
        />
      ))}
      {button.map((item) => (
        <Input key={item?.value} {...item} />
      ))}
    </div>
  );
};

export default WorkflowsTableSearch;
