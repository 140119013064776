import React from "react";

import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

const validToken = sessionStorage.getItem("validToken");
const UserLoggedOut = ({ user }) => (
  <>
    <CssBaseline />
    <Container fixed>
      <div className="annuity_logout">
        {validToken === "true"
          ? "You have been logged out. You can now safely close this browser tab."
          : "Session has been logged out due to inactivity."}
      </div>
    </Container>
  </>
);

export default UserLoggedOut;
