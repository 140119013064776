import { currentCalenderYear, formatDate, momentDate } from "@lib/date";

export const getAsOfDate = (dob, policyDate) => {
  const dobMoment = momentDate(dob);
  const policyIssueDateMoment = policyDate ? momentDate(policyDate) : null;
  const currentYear = currentCalenderYear;
  const lastBirthday = dobMoment.year(currentYear).isAfter(formatDate())
    ? dobMoment.year(currentYear - 1)
    : dobMoment.year(currentYear);
  const resultDate = policyIssueDateMoment?.isAfter(lastBirthday)
    ? policyIssueDateMoment
    : lastBirthday;

  return formatDate(resultDate);
};
