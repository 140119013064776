import { Fragment, useCallback, useState } from "react";

import { flexRender } from "@tanstack/react-table";
import { Collapse } from "reactstrap";

const TableBody = (props) => {
  const {
    rows,
    renderNestedTable,
    getAllLeafColumns,
    noDataMessage,
    noBottomBorder = false,
    isEditable = false,
  } = props || {};
  const [expandedRowIds, setIsExpandedRowId] = useState([]);

  const handleToggle = useCallback(
    (targetRowId) => {
      const isRowExpanded = expandedRowIds.includes(targetRowId);
      const updatedExpandedRowIds = isRowExpanded
        ? expandedRowIds.filter((rowId) => rowId !== targetRowId)
        : [...expandedRowIds, targetRowId];
      setIsExpandedRowId(updatedExpandedRowIds);
    },
    [expandedRowIds]
  );

  return (
    <tbody className={noBottomBorder ? "border-0" : "border-bottom"}>
      {rows?.length ? (
        rows?.map((row) => (
          <Fragment key={row?.id}>
            <tr key={row?.id}>
              {row?.getVisibleCells()?.map((cell) => {
                const isAction = cell?.column?.id === "actions";
                const isEditableCell = isEditable && !isAction;
                const handleRowExpanded = () => {
                  if (renderNestedTable && !isAction) {
                    handleToggle(row?.id);
                  }
                };
                return (
                  <td
                    key={cell?.id}
                    className={`pr-0 align-middle${
                      isEditableCell ? " editable-td" : ""
                    }`}
                    onClick={handleRowExpanded}
                  >
                    {flexRender(cell?.column?.columnDef?.cell, {
                      ...cell.getContext(),
                      expandedRowIds,
                    })}
                  </td>
                );
              })}
            </tr>

            {renderNestedTable && expandedRowIds.includes(row.id) ? (
              <tr className="border-0">
                <td className="p-0" colSpan={getAllLeafColumns().length}>
                  <Collapse isOpen={expandedRowIds.includes(row.id)}>
                    {renderNestedTable({ row })}
                  </Collapse>
                </td>
              </tr>
            ) : null}
          </Fragment>
        ))
      ) : (
        <tr>
          <td colSpan="9" className="text-center">
            {noDataMessage}
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
