import React from "react";

import { Container } from "reactstrap";

import ProcessingLogTable from "./components/ProcessingLogTable";

const ProcessingLog = () => (
  <Container>
    <div className="d-flex justify-content-between align-items-center">
      <h3>Purchases & Withdrawals Processing Log</h3>
      <div>
        <button className="btn btn-success">Refresh</button>
      </div>
    </div>

    <ProcessingLogTable />
  </Container>
);

export default ProcessingLog;
