/* eslint-disable camelcase */
import common_de from "./de/common.json";
import errors_de from "./de/errors.json";
import common_en from "./en/common.json";
import errors_en from "./en/errors.json";
import common_fr from "./fr/common.json";
import errors_fr from "./fr/errors.json";

export default {
  en: {
    common: common_en,
    errors: errors_en,
  },
  fr: {
    common: common_fr,
    errors: errors_fr,
  },
  de: {
    common: common_de,
    errors: errors_de,
  },
};
