import { useEffect, useMemo, useState } from "react";

import { Modal } from "reactstrap";

import { Input } from "@atoms";
import { msgs, RETIREMENT_AGE } from "@config/conf";
import { formatToPercentage, sortBy } from "@containers/components/helpers";
import useToast from "@hooks/useToast";
import { sanitizeNumber } from "@lib/number";
import { debounce } from "@utils/debounce";

import { getAsOfDate } from "../helpers";
import IncomePaymentTypeToggle from "./helperComponent/IncomePaymentTypeToggle";

const LeftSideIncomeProjections = ({
  form,
  incomeCalculationData,
  policyData,
}) => {
  const { control, watch, setValue } = form || {};
  const { pipRateJoint, pipRateSingle } = incomeCalculationData || {};
  const { dob, policyIssueDate, pipRates } = policyData || {};
  const { incomePaymentType, participantAge, spouseAge } = watch();
  const withdrawalRate = incomePaymentType ? pipRateJoint : pipRateSingle;

  const [pipModal, setPipModal] = useState(false);

  const onTogglePipModal = () => setPipModal(!pipModal);
  const openPipModal = () => setPipModal(true);
  const { showErrorToast } = useToast();

  const SpouseInfo = () => (
    <>
      What age will your{" "}
      <span className="font-weight-bold font-italic text-dark">spouse</span> be
      when you turn {participantAge}
    </>
  );

  const data = [
    {
      name: "participantAge",
      label: "Enter your age when starting lifetime income",
    },
    {
      name: "spouseAge",
      label: <SpouseInfo />,
    },
    {
      name: "withdrawalRate",
      label: "Personal Lifetime Withdrawal Percentage",
    },
  ];

  const debouncedValidateAges = debounce(() => {
    if (participantAge < 60 || spouseAge < 60) {
      showErrorToast(
        "Younger spouse age must be greater than 59 to start income"
      );
    }
  }, 800);

  useEffect(() => {
    debouncedValidateAges();
    return () => debouncedValidateAges.cancel();
  }, [participantAge, spouseAge]);

  const handleOnBlur = (event) => {
    const { name, value } = event.target || {};
    const inputValue = sanitizeNumber(value, { allowDecimal: false });

    if (name === "participantAge" || name === "spouseAge") {
      const ageToSet = inputValue < 60 ? RETIREMENT_AGE : inputValue;
      setValue(name, ageToSet, { shouldValidate: true });
    }
  };

  const filteredData = useMemo(
    () =>
      incomePaymentType
        ? data
        : data?.filter(({ name }) => name !== "spouseAge"),
    [incomePaymentType, participantAge]
  );

  const asOfDate = useMemo(
    () => getAsOfDate(dob, policyIssueDate),
    [dob, policyIssueDate]
  );

  const sortedPipRates = useMemo(
    () => sortBy(pipRates, "pipMinimumAge"),
    [pipRates]
  );

  const renderPipModal = () => (
    <Modal
      isOpen={pipModal}
      toggle={onTogglePipModal}
      size="lg"
      centered
      className="pip-modal"
    >
      <div className="w-50 mx-auto table-container overflow-auto">
        <table>
          <thead>
            <tr className="table-head">
              <th className="w-25">
                <div>Age Range</div>
                <small className="font-weight-bold">
                  (if joint, age younger spouse)
                </small>
              </th>
              <th className="w-25">Single Life Withdrawal Rate</th>
              <th className="w-25">Joint Life Withdrawal Rate</th>
            </tr>
          </thead>
          <tbody>
            {sortedPipRates?.length ? (
              sortedPipRates.map((data, index) => (
                <tr key={index} className="table-row">
                  <td>{`${data?.pipMinimumAge} - ${data?.pipMaximumAge}`}</td>
                  <td>
                    {data?.pipWithdrawalRateSingle
                      ? formatToPercentage(data?.pipWithdrawalRateSingle * 100)
                      : ""}
                  </td>
                  <td>
                    {data?.pipWithdrawalRateJoint
                      ? formatToPercentage(data?.pipWithdrawalRateJoint * 100)
                      : ""}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="table-row">
                <td colSpan="3">{msgs.noRows}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="text-left mt-3">
        <div>
          The Personal Lifetime Withdrawal Percentage is a number that is used
          to determine your initial amount of income in the first year of
          retirement. This number remains constant, but you have an opportunity
          to earn interest (indexed or fixed) and receive an Income Accelerator
          bonus each year during retirement. This could increase the amount of
          income you can receive in the future.
        </div>

        <div className="question font-weight-bold">
          How is income determined?
        </div>
        <div>
          To calculate the initial amount of monthly income you can take out of
          your annuity, multiply your Personal Lifetime Withdrawal Percentage by
          your Current Lifetime Income Value and divide by 12. Rates may change
          based on when a contribution is received.
        </div>

        <div className="question font-weight-bold">
          How do we determine your Personal Lifetime Withdrawal Percentage?
        </div>
        <div>
          Your Personal Lifetime Withdrawal Percentage will be based on your age
          (or, if joint elected, the younger of you and your spouse&apos;s age)
          at the time of election and the Personal Lifetime Withdrawal
          Percentages that were in effect when you made the contribution.
        </div>
        <div className="withdrawal-rate-date">Rates as of {asOfDate}</div>
      </div>
    </Modal>
  );

  useEffect(() => {
    setValue("withdrawalRate", formatToPercentage(withdrawalRate * 100));
  }, [withdrawalRate]);

  return (
    <div className="px-3 w-75">
      <IncomePaymentTypeToggle
        name="incomePaymentType"
        control={control}
        defaultValue={false}
      />
      {filteredData?.map(({ name, label }) => {
        const isPipRateSingle = name === "withdrawalRate";
        const inactiveClass = isPipRateSingle ? "bg-inactive w-60" : "mr-3";

        return (
          <div key={name} className="mt-3">
            <Input
              name={name}
              control={control}
              label={label}
              className={`rounded font-weight-bold text-center border border-secondary ${inactiveClass}`}
              isReadOnly={isPipRateSingle}
              maxLength={3}
              onBlur={handleOnBlur}
              formatter={{ numericOnly: true }}
            />
          </div>
        );
      })}
      <button
        className="btn btn-link text-primary button-link"
        onClick={openPipModal}
      >
        Learn More
      </button>
      {renderPipModal()}
    </div>
  );
};

export default LeftSideIncomeProjections;
