import React, { useEffect, useMemo, useState } from "react";

import { useForm, useFieldArray } from "react-hook-form";
import { Badge } from "reactstrap";

import { Spinner, Table, TableDashboard, FormFooterActions } from "@atoms";
import {
  displayNumberFormat,
  splitStringyNumber,
} from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import { sanitizeNumber } from "@lib/number";

const queryPath = ["sortProductName", "sortCarrierName", "pageSize", "from"];

const zeroValue = displayNumberFormat(0);
const defaultValue = {
  rateID: "",
  minAge: zeroValue,
  singleWithdrawalRate: zeroValue,
  jointWithdrawalRate: zeroValue,
};

const WithdrawalRates = ({
  match: {
    params: { id: productId },
  },
}) => {
  const { get, put, isPending } = useApiFetch();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const [refresh, setRefresh] = useState(false);
  const [withdrawals, setWithdrawals] = useState([]);

  const { control, setValue, getValues } = useForm();
  const { append, remove } = useFieldArray({
    control,
    name: "withdrawalRatesTable",
  });

  const { withdrawalRatesTable = [] } = getValues();

  const { from: page, ...restParams } = getQueryParams(queryPath);

  const getWithdrawalRatesData = async () => {
    if (productId) {
      const response = await get(`products/wr/${productId}`);
      setWithdrawals(response?.result);
    }
  };

  useEffect(() => {
    getWithdrawalRatesData();
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    if (withdrawals?.length) {
      const data = withdrawals?.map(
        ({ minAge, singleWithdrawalRate, jointWithdrawalRate }) => ({
          minAge: displayNumberFormat(minAge),
          singleWithdrawalRate: displayNumberFormat(singleWithdrawalRate * 100),
          jointWithdrawalRate: displayNumberFormat(jointWithdrawalRate * 100),
        })
      );

      setValue("withdrawalRatesTable", data);
    }
  }, [withdrawals]);

  const handleBlur = (rowIndex, columnId) => {
    const fieldName = `withdrawalRatesTable.${rowIndex}.${columnId}`;
    const activeIndexValue = getValues(fieldName);
    const numericTypedValue = splitStringyNumber(activeIndexValue);
    if (columnId === "minAge") {
      const value =
        numericTypedValue > 150
          ? displayNumberFormat(150)
          : numericTypedValue.toFixed(2);
      setValue(fieldName, value);
    } else setValue(fieldName, numericTypedValue.toFixed(2));
  };

  const column = [
    {
      id: "minAge",
      header: "Minimum Age",
      minWidth: 300,
      accessorFn: ({ minAge }) => displayNumberFormat(minAge),
    },
    {
      id: "singleWithdrawalRate",
      header: "Single Rate",
      alignRight: true,
      minWidth: 300,
      accessorFn: ({ singleWithdrawalRate }) =>
        displayNumberFormat(singleWithdrawalRate),
    },
    {
      id: "jointWithdrawalRate",
      header: "Joint Rate",
      alignRight: true,
      minWidth: 300,
      accessorFn: ({ jointWithdrawalRate }) =>
        displayNumberFormat(jointWithdrawalRate),
    },
    {
      id: "actions",
      header: "",
      size: "40%",
      cell: ({ row: { id: rowIndexId } = {} }) => {
        const handleDelete = () => remove(rowIndexId);

        return (
          <div className="pr-2 column-end">
            <Badge
              color="danger"
              className="pointer my-2"
              onClick={handleDelete}
            >
              Delete
            </Badge>
          </div>
        );
      },
    },
  ];

  const handleSubmit = async () => {
    const payloadValues = withdrawalRatesTable.map((row) => {
      const { jointWithdrawalRate, minAge, singleWithdrawalRate } = row || {};
      return {
        minAge: Number(minAge),
        jointWithdrawalRate: sanitizeNumber(jointWithdrawalRate) / 100,
        singleWithdrawalRate: sanitizeNumber(singleWithdrawalRate) / 100,
      };
    });
    await put(`products/wr/${productId}`, payloadValues);
  };

  const handleAddWRates = () => {
    append(defaultValue);
  };

  const tableFooter = useMemo(() => {
    const queryParams = buildQueryParams({ page, ...restParams });

    const buttonActions = {
      buttonProps: [
        { labelName: "Add", onClick: handleAddWRates },
        { onClick: handleSubmit },
      ],
    };
    return (
      <FormFooterActions
        linkProps={[{ path: `/products?${queryParams}` }]}
        {...buttonActions}
      />
    );
  }, [handleSubmit, page, restParams]);

  return (
    <TableDashboard pageTitle="Withdrawal Rates" setRefresh={setRefresh}>
      <Spinner requesting={isPending}>
        <Table
          columns={column}
          data={withdrawalRatesTable}
          hidePagination
          footer={tableFooter}
          isEditable
          editableProps={{
            control,
            tableName: "withdrawalRatesTable",
            onBlur: handleBlur,
            maxLength: 20,
            shouldPercentage: true,
            numericOnly: true,
            alignRight: true,
          }}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default WithdrawalRates;
