import { Container } from "reactstrap";

import ChangePasswordForm from "./components/ChangePasswordForm";

const ChangePassword = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Change Password</h3>

    <ChangePasswordForm />
  </Container>
);

export default ChangePassword;
