import React, { useCallback } from "react";

import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import {
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import { pagination } from "@config/conf";

const ReactTablePagination = ({
  dataLength,
  page,
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageSize,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageSize,
  manualPageSize,
  pageNumber,
}) => {
  const arrayPageIndex =
    pageNumber - 2 < 0
      ? pageOptions?.slice(0, pageNumber + 3)
      : pageOptions?.slice(pageNumber - 2, pageNumber + 3);

  const onPageSizeChange = useCallback(
    (event) => {
      const pageSizeValue = Number(event.target.value);
      setPageSize(Number(pageSizeValue));
      gotoPage(1, pageSizeValue);
    },
    [setPageSize, gotoPage]
  );

  return (
    <Pagination className="pagination mt-3" dir="ltr">
      <div className="pagination">
        {dataLength > pageSize ? (
          <>
            <PaginationLink
              className="pagination__link pagination__link--arrow"
              type="button"
              onClick={() => gotoPage(1, pageSize)}
              disabled={!canPreviousPage}
            >
              <ChevronDoubleLeftIcon className="pagination__link-icon" />
            </PaginationLink>
            <PaginationLink
              className="pagination__link pagination__link--arrow"
              type="button"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              <ChevronLeftIcon className="pagination__link-icon" />
            </PaginationLink>
            {arrayPageIndex?.map((i) => (
              <PaginationItem
                className="pagination__item"
                active={pageNumber === i}
                key={i}
              >
                <PaginationLink
                  key={i}
                  className="pagination__link"
                  type="button"
                  onClick={() => gotoPage(i, pageSize)}
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem className="pagination__item">
              <PaginationLink
                className="pagination__link pagination__link--arrow"
                type="button"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                <ChevronRightIcon className="pagination__link-icon" />
              </PaginationLink>
            </PaginationItem>
            <PaginationItem className="pagination__item">
              <PaginationLink
                className="pagination__link pagination__link--arrow"
                type="button"
                onClick={() => gotoPage(pageOptions.length, pageSize)}
                disabled={!canNextPage}
              >
                <ChevronDoubleRightIcon className="pagination__link-icon" />
              </PaginationLink>
            </PaginationItem>
          </>
        ) : null}
        {dataLength ? (
          <PaginationItem className="pagination__item pagination-info">
            Showing {pageIndex + 1} to {pageIndex + page?.length} of{" "}
            {dataLength} results
          </PaginationItem>
        ) : null}
        {dataLength > pagination.length ? (
          <PaginationItem className="pagination__item">
            <FormGroup className="pagination__select-form ">
              <Input
                className="pagination__item pagination-info"
                type="select"
                name="select"
                id="page-size-select"
                value={pageSize}
                onChange={onPageSizeChange}
              >
                {manualPageSize.map((item) => (
                  <option
                    className="pagination__item pagination__item-option"
                    key={item}
                    value={item}
                  >
                    Show {item}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </PaginationItem>
        ) : null}
      </div>
    </Pagination>
  );
};

export default ReactTablePagination;
