import React from "react";

import { Container } from "reactstrap";

import { sanitizeValue } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useToast from "@hooks/useToast";
import { sanitizeNumber } from "@lib/number";

import PlanForm from "../PlanForm/PlanForm";

const recordkeeperID = localStorage.getItem("recordkeeperID");
const custodianId = localStorage.getItem("custodianID");

const defaultData = {
  recordkeeperID,
  planName: "",
  federalTaxID: "",
  carrierPlanIdentifier: "",
  address1: "",
  address2: "",
  city: "",
  stateCode: "AK",
  zip: "",
  countryCode: "USA",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
  custodianID: custodianId,
  custodialAccount: "",
  notes: "",
  status: "Active",
  incomeActivationStatus: "",
  custodialTpaCode: null,
  custodialTrustAccount: null,
  rkAccount: null,
};

const AddPlan = () => {
  const { post } = useApiFetch();
  const { validateRequiredField } = useToast();

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      planName,
      carrierPlanIdentifier,
      address1,
      city,
      zip,
      custodialTpaCode,
      custodialTrustAccount,
      rkAccount,
      federalTaxID,
    } = values;

    const isValid = validateRequiredField({
      "plan Name": planName,
      "plan Identifier": carrierPlanIdentifier,
      "Address 1": address1,
      City: city,
      Zip: zip,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      federalTaxID: federalTaxID ? sanitizeNumber(federalTaxID) : "",
      custodialTpaCode: !custodialTpaCode?.length ? null : custodialTpaCode,
      custodialTrustAccount: !custodialTrustAccount?.length
        ? null
        : custodialTrustAccount,
      rkAccount: !rkAccount?.length ? null : rkAccount,
    };

    post("plans/create", payloadValues, { redirectUrl: "/plans" });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Detail</h3>
      <PlanForm data={defaultData} redirectUrl="/plans" onSubmit={handleForm} />
    </Container>
  );
};

export default AddPlan;
