import React, { useRef } from "react";

import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router";

import { msgs, INACTIVE_TIMEOUT } from "@config/conf";
import { useAuth } from "@contexts/AuthContext";
import useToast from "@hooks/useToast";

const IdleTimerContainer = () => {
  const idleTimerRef = useRef(null);
  const { user, logout } = useAuth();
  const { showErrorToast } = useToast();
  const history = useHistory();

  const onIdleTimer = () => {
    if (user) {
      logout();
      showErrorToast(msgs.authError);
      history.push("/");
    }
  };

  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: INACTIVE_TIMEOUT,
    onIdle: onIdleTimer,
  });

  return <div idletimer={idleTimer} />;
};

export default IdleTimerContainer;
