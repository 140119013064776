import React from "react";

import { app, conf, msgs, pagination } from "@config/conf";

const Environment = () => (
  <p>
    {JSON.stringify({
      environment: app.environment,
      successMessage: msgs.success,
      errorMessage: msgs.error,
      emptyTableMessage: msgs.noRows,
      authErrorMessage: msgs.authError,
      serverURL: conf.serverURL,
      noActivityTimeOut: conf.idleTimeout,
      pageLength: pagination.length,
      pageLengthDropdown: pagination.list,
    })}
  </p>
);

export default Environment;
