const CustodiansPlansTableData = () => {
  const header = [
    { id: 1, title: "#" },
    { id: 2, title: "First Name" },
    { id: 3, title: "Last Name" },
    { id: 4, title: "Username" },
    { id: 5, title: "Status" },
  ];

  const headerResponsive = [
    { id: 1, title: "#" },
    { id: 2, title: "Plan Name" },
    { id: 3, title: "Federal Tax ID" },
    { id: 4, title: "Plan Contact Name" },
    { id: 5, title: "Plan Phone" },
    { id: 6, title: "Plan Email" },
    { id: 7, title: "Custodin Plan Code" },
    { id: 8, title: "Routing Number" },
    { id: 9, title: "Account Number" },
    { id: 10, title: "Active" },
    { id: 11, title: "" },
  ];

  const rows = [
    {
      id: 1,
      planName: "Maria",
      federalTaxId: "klasjdkf83us",
      planContactName: "@dragon",
      planPhone: "832479283",
      planEmail: "Work@gmail.com",
      custodianPlanCode: "21",
      routingNumber: "8324792",
      accountNumber: "wkdjlqwje923298asd",
      active: true,
    },
    {
      id: 2,
      planName: "Bobby",
      federalTaxId: "lkdfjlds",
      planContactName: "@bobby",
      planPhone: "83749823",
      planEmail: "bobby@gmail.com",
      custodianPlanCode: "37",
      routingNumber: "92384",
      accountNumber: "934poqewkwd",
      active: true,
    },
    {
      id: 3,
      planName: "Alexander",
      federalTaxId: "c;laodkif93",
      planContactName: "@alexa",
      planPhone: "93248023",
      planEmail: "alexa@gmail.com",
      custodianPlanCode: "783",
      routingNumber: "3849823",
      accountNumber: "ldakfjlaksl",
      active: true,
    },
    {
      id: 4,
      planName: "Vera",
      federalTaxId: "oq83urqiwed",
      planContactName: "@vera",
      planPhone: "9382472983",
      planEmail: "vera@gmail.com",
      custodianPlanCode: "834",
      routingNumber: "384983409",
      accountNumber: "skldjaw9e89wsda",
      active: true,
    },
    {
      id: 5,
      planName: "Maria",
      federalTaxId: "klasjdkf83us",
      planContactName: "@dragon",
      planPhone: "832479283",
      planEmail: "Work@gmail.com",
      custodianPlanCode: "21",
      routingNumber: "8324792",
      accountNumber: "wkdjlqwje923298asd",
      active: true,
    },
  ];

  const custodiansPlansTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
    tableRowsData: rows,
  };
  return custodiansPlansTableData;
};

export default CustodiansPlansTableData;
