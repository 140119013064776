import { CSVLink } from "react-csv";
import Badge from "reactstrap/lib/Badge";
import Button from "reactstrap/lib/Button";

const componentsMap = {
  badge: Badge,
  button: Button,
  div: "div",
};

const ExportToCsv = ({
  loadingResponse,
  getCsvExport,
  fileName,
  csvRef,
  csvData,
  colorVariant = "primary",
  className,
  tableCellSpecifier = {},
  component = "button",
}) => {
  const CsvAction = componentsMap?.[component.toLowerCase()] || Button;
  const { hasExactCell = false } = tableCellSpecifier;
  const isBadge = component.toLowerCase() === "badge";
  const csvActionClass = `${className || ""}${
    isBadge ? "btn-blue pointer" : ""
  }`;

  const renderCsvLink = () => {
    const shouldRenderCsvLink = isBadge && !hasExactCell;
    return shouldRenderCsvLink ? null : (
      <CSVLink
        ref={csvRef}
        filename={fileName}
        className="d-none"
        data={csvData}
      >
        <Badge color="primary">Export</Badge>
      </CSVLink>
    );
  };

  return (
    <>
      <CsvAction
        color={colorVariant}
        onClick={getCsvExport}
        className={csvActionClass}
      >
        {loadingResponse ? (
          <span className="spinner-border spinner-border-sm" />
        ) : (
          <>Export</>
        )}
      </CsvAction>
      {renderCsvLink()}
    </>
  );
};

export default ExportToCsv;
