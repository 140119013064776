import React, { useCallback } from "react";

import { useForm } from "react-hook-form";
import Container from "reactstrap/lib/Container";

import { sanitizeValue } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";

import ProductFactorForm from "./ProductFactorForm";

const defaultValues = {
  factorName: "",
  joint: true,
  jointPercent: "0",
  refundable: true,
  productName: "",
  deferralYears: "0",
  selectable: true,
  status: "Active",
};

const AddProductFactor = (props) => {
  const {
    match: {
      params: { id: productID },
    },
  } = props;
  const productFactorForm = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { post } = useApiFetch();
  const { reset } = productFactorForm || {};

  const handleSubmit = useCallback(
    async (values) => {
      const {
        jointPercent,
        deferralYears,
        factorName,
        refundable,
        joint,
        selectable,
        status,
      } = values || {};
      const payloadValues = {
        deferralYears: Number(deferralYears),
        jointPercent: Number(jointPercent),
        factorName: sanitizeValue(factorName),
        productID,
        refundable,
        joint,
        selectable,
        status,
      };

      await post("products/productfactors", payloadValues);
      reset({ ...defaultValues });
    },
    [productID]
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3">Product Factor Detail</h3>
      <ProductFactorForm
        form={productFactorForm}
        onSubmit={handleSubmit}
        productID={productID}
      />
    </Container>
  );
};

export default AddProductFactor;
