import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Container } from "reactstrap";

import { sanitizeValue } from "@containers/components/helpers";
import { FormSpinner } from "@containers/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import useToast from "@hooks/useToast";
import { sanitizeNumber } from "@lib/number";

import PlanForm from "../PlanForm/PlanForm";

const queryParams = [
  "from",
  "search",
  "pageSize",
  "sortPlanName",
  "sortStatus",
];

const EditPlans = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { get, put, isPending } = useApiFetch();
  const [planData, setPlanData] = useState();

  const { validateRequiredField } = useToast();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: page, ...restParams } = getQueryParams(queryParams);

  const getPlan = useCallback(async () => {
    const response = await get(`plans/${id}`);
    setPlanData(response);
  }, [id]);

  useEffect(() => {
    getPlan();
  }, [getPlan]);

  const redirectUrl = useMemo(() => {
    const queryParams = buildQueryParams({ page, ...restParams });
    return `/plans?${queryParams}`;
  }, [page, restParams]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      planName,
      carrierPlanIdentifier,
      address1,
      city,
      zip,
      custodialTpaCode,
      custodialTrustAccount,
      rkAccount,
      federalTaxID,
    } = values;

    const isValid = validateRequiredField({
      "plan Name": planName,
      "plan Identifier": carrierPlanIdentifier,
      "Address 1": address1,
      City: city,
      Zip: zip,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      federalTaxID: federalTaxID ? sanitizeNumber(federalTaxID) : "",
      custodialTpaCode: !custodialTpaCode?.length ? null : custodialTpaCode,
      custodialTrustAccount: !custodialTrustAccount?.length
        ? null
        : custodialTrustAccount,
      rkAccount: !rkAccount?.length ? null : rkAccount,
    };

    put(`plans/${id}`, payloadValues, { redirectUrl });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Detail</h3>
      <FormSpinner requesting={isPending} />
      <PlanForm
        onSubmit={handleForm}
        redirectUrl={redirectUrl}
        data={planData}
      />
    </Container>
  );
};

export default EditPlans;
