import { useCallback, useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { Container } from "reactstrap";

import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";

import ProductsForm from "../ProductsForm/ProductsForm";

const queryPath = ["from", "pageSize", "sortProductName", "sortCarrierName"];

const EditProduct = () => {
  const { id: paramsProductId } = useParams() || {};
  const { getQueryParams, buildQueryParams } = useQueryParams();
  const { get, isPending } = useApiFetch();
  const [productsData, setProductsData] = useState({});

  const { from: page, ...restParams } = getQueryParams(queryPath);

  const redirectUrl = useMemo(() => {
    const queryParams = buildQueryParams({ page, ...restParams });
    return `/products?${queryParams}`;
  }, [page, restParams]);

  const getSingleProduct = useCallback(async () => {
    const data = await get(`products/${paramsProductId}`);
    setProductsData(data);
  }, [paramsProductId]);

  useEffect(() => {
    getSingleProduct();
  }, [getSingleProduct]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Product Detail</h3>
      <FormSpinner requesting={isPending} />
      <ProductsForm redirectUrl={redirectUrl} products={productsData} />
    </Container>
  );
};

export default EditProduct;
