import { useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, Select, FormFooterActions } from "@atoms";
import { msgs } from "@config/conf";
import { filterOptions } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";

const { fieldError } = msgs || {};

const ConfigForm = ({ data, onSubmit }) => {
  const { control, handleSubmit, reset } = useForm();
  const { get, isPending } = useApiFetch();
  const [usersData, setUsersData] = useState([]);

  const getUsersData = async () => {
    const response = await get("users/get");
    setUsersData(response?.data);
  };

  useEffect(() => {
    getUsersData();
  }, []);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const requiredProps = {
    control,
    required: true,
    rules: { required: fieldError },
  };

  const userOptions = useMemo(
    () => filterOptions(usersData, "userID", "userName"),
    [usersData]
  );

  const validateTokenTimeout = (value) => {
    if (value < 1) {
      return "Should be greater than 0.";
    }
    if (value > 60) {
      return "Should be less than or equal to 60.";
    }
    return true;
  };

  const formFooterProps = {
    linkProps: [{ labelName: "Cancel", path: "/config/re" }],
    buttonProps: [{ onClick: handleSubmit(onSubmit) }],
  };

  return (
    <div>
      <Card className="container_width">
        <CardBody>
          <h4 className="mb-2 font-weight-bolder">Token</h4>
          <div className="form form--horizontal form_spacing">
            <Input
              {...requiredProps}
              name="tokenTimeoutMins"
              label="Token Timeout (Minutes)"
              maxLength={2}
              rules={{
                required: fieldError,
                validate: validateTokenTimeout,
              }}
            />
            <h4 className="mt-3 mb-2 font-weight-bolder">SMTP</h4>
            <Input
              {...requiredProps}
              name="smtpHost"
              label="SMTP Host"
              maxLength={500}
            />
            <Input
              {...requiredProps}
              name="smtpPort"
              label="SMTP Port"
              maxLength={10}
            />
            <Input
              {...requiredProps}
              name="smtpUserName"
              label="SMTP Username"
              maxLength={100}
            />
            <Input
              {...requiredProps}
              name="smtpPassword"
              label="SMTP Password"
              maxLength={100}
            />
            <h4 className="mt-3 mb-2 font-weight-bolder">System</h4>
            <Select
              {...requiredProps}
              label="System User"
              name="systemUserID"
              isLoading={isPending}
              options={userOptions}
            />
            <FormFooterActions {...formFooterProps} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ConfigForm;
