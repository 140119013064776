import React, { useState } from "react";

import { TableDashboard } from "@atoms";

import ProductsTable from "./components/ProductsTable";

const Products = () => {
  const [refresh, setRefresh] = useState(false);

  return (
    <TableDashboard
      pageTitle="Products"
      setRefresh={setRefresh}
      navigatePath="/products/add"
    >
      <ProductsTable refresh={refresh} setRefresh={setRefresh} />
    </TableDashboard>
  );
};

export default Products;
