import { useState, useEffect, useCallback } from "react";

import { Container } from "reactstrap";

import { FormSpinner } from "@containers/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";

import WorkflowTypeIdForm from "./WorkflowTypeIdForm";

const additionalFormFields = [{ label: "Date of Birth", name: "dob" }];

const WorkflowTypeID1002 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const { get, isPending } = useApiFetch();

  const [fetchData, setFetchData] = useState({});

  const getData = useCallback(async () => {
    const data = await get(`workflows/workflowdetail1002/${workflowId}`);
    setFetchData(data);
  }, [workflowId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Participant DOB Update</h3>

      <FormSpinner requesting={isPending} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={fetchData}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID1002;
