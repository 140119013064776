import React, { useState } from "react";

import SortIcon from "mdi-react/SortIcon";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";

import ProcessingLogTableSearch from "./ProcessingLogTableSearch";

const ProcessingLogTable = () => {
  const [searchInput, setSearchInput] = useState("");
  const [date, setDate] = useState("");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("from");

  return (
    <>
      <Card className="pb-2">
        <CardBody className="py-2">
          <ProcessingLogTableSearch
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            date={date}
            setDate={setDate}
          />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Table className="table--bordered mb-3" responsive>
            <thead>
              <th>Source Ref</th>
              <th>
                <span className="react-table__column-header sortable">
                  RE Account #{" "}
                  <span className="ml-2">
                    <SortIcon />
                  </span>
                </span>
              </th>
              <th style={{ width: "0%" }}>
                <span className="react-table__column-header sortable">
                  Date{" "}
                  <span className="ml-2">
                    <SortIcon />
                  </span>
                </span>
              </th>
              <th>Amount</th>
              <th>Result</th>
              <th>WF #</th>
              <th>Status</th>
            </thead>
          </Table>
          <div>
            <Link
              to={`/workflows?page=${page}`}
              className="btn btn-outline-danger"
            >
              Cancel
            </Link>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ProcessingLogTable;
