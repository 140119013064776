import { useCallback, useEffect, useRef, useState } from "react";

import { CSVLink } from "react-csv";
import { Button, Badge } from "reactstrap";

import useApiFetch from "@hooks/useApiFetch";
import { formatDate } from "@lib/date";

const ExportButton = ({ fromDate }) => {
  const { get, isPending } = useApiFetch();

  const csvClickRef = useRef();
  const [triggerDownload, setTriggerDownload] = useState();
  const [csvData, setCsvData] = useState([]);

  const csvFileName = `DailySettlementSummaryList_${formatDate(
    fromDate,
    "MM_DD_YYYY"
  )}.csv`;

  const getExportData = useCallback(async () => {
    const data = await get(
      `reports/dailysettlementsummary/export?date=${fromDate}`
    );
    setCsvData(data?.response);
    setTriggerDownload(true);
  }, [fromDate]);

  useEffect(() => {
    if (csvData?.length && triggerDownload) {
      csvClickRef.current.link.click();
      setTriggerDownload(false);
    }
  }, [csvData, triggerDownload]);

  return (
    <>
      <div className="flex align-items-center justify-content-between">
        <div className="flex align-items-center">
          <div className="ml-4 pl-1 font-weight-bold">Settlement Date:</div>
          <h4 className="ml-2">{formatDate(fromDate)}</h4>
        </div>
        <Button color="primary" onClick={getExportData} className="m-0">
          {isPending ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Export"
          )}
        </Button>
        <CSVLink
          filename={csvFileName}
          className="d-none"
          ref={csvClickRef}
          data={csvData}
        >
          <Badge color="primary">Export</Badge>
        </CSVLink>
      </div>
      <h4 className="ml-3 mt-3">Custodian Money Settlement</h4>
    </>
  );
};

export default ExportButton;
