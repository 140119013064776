import PropTypes from "prop-types";
import { Container } from "reactstrap";

import CustodiansPlansTableData from "../components/CustodiansPlansTableData";
import EditCustodianPlanForm from "./components/EditCustodianPlanForm";

const { tableRowsData } = CustodiansPlansTableData();

const EditCustodianPlan = (props) => {
  const {
    match: {
      params: { custId, planId },
    },
  } = props;
  const data = tableRowsData.find((row) => row.id === parseInt(planId, 10));

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Edit Plan</h3>

      <EditCustodianPlanForm data={data} custodianId={custId} />
    </Container>
  );
};

EditCustodianPlan.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      custId: PropTypes.number.isRequired,
      planId: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default EditCustodianPlan;
