import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";

import { Spinner } from "@atoms";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";

import ViewAccountForm from "./components/ViewAccountForm";

const ViewAccountSummary = ({
  match: {
    params: { id: accountNumber },
  },
}) => {
  const currentTab = Number(sessionStorage.getItem("activeTab"));
  const { get, errorMessage, isPending } = useApiFetch();
  const { getQueryParams } = useQueryParams();
  const token = getQueryParams("token");
  const history = useHistory();

  const [annuityData, setAnnuityData] = useState([]);
  const activeTab = currentTab || (annuityData?.length > 1 ? 0 : 1);

  const getAnnuitant = useCallback(async () => {
    const response = await get(
      `reaccounts/annuitydetails/${accountNumber}/true`,
      { errorToast: false }
    );
    setAnnuityData(response?.data);
  }, [accountNumber]);

  useEffect(() => {
    getAnnuitant();
    if (token) {
      sessionStorage.setItem("annuityAuthToken", decodeURI(token));
      sessionStorage.setItem("reAccountNumber", accountNumber);
      sessionStorage.setItem("activeTab", activeTab);
      sessionStorage.setItem("validToken", true);
      // default the managedFlag to false and terminationFlag to true for admin user so that they can see all links and buttons.
      sessionStorage.setItem("managedFlag", false);
      sessionStorage.setItem("terminationFlag", true);
      history.push(`/View/Account/Summary/${accountNumber}`);
    }
  }, []);

  return (
    <Spinner requesting={isPending}>
      <Container>
        <ViewAccountForm
          activeTab={activeTab}
          data={annuityData}
          errorResponse={errorMessage}
        />
      </Container>
    </Spinner>
  );
};

export default ViewAccountSummary;
