import React from "react";

const TableFooter = ({ children, colspan }) => (
  <tfoot>
    <tr>
      <td className="p-0" colSpan={colspan}>
        {children}
      </td>
    </tr>
  </tfoot>
);

export default TableFooter;
