import React from "react";

import MaskedInput from "react-text-mask";
import { Form, Input } from "reactstrap";

const ProcessingLogTableSearch = ({
  searchInput,
  setSearchInput,
  date,
  setDate,
}) => {
  const handleClear = (e) => {
    e.preventDefault();
    setSearchInput("");
    setDate("");
  };

  return (
    <div className="table__search mb-0">
      <Form
        inline
        className="w-100"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input
          type="text"
          placeholder="RE Account Number"
          className="table__search table__search-input mb-0"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{ maxWidth: "200px", width: "100%" }}
          maxLength="20"
        />
        <MaskedInput
          mask={[/[0-1]/, /\d/, "/", /[0-3]/, /\d/, "/", /\d/, /\d/]}
          guide={false}
          type="text"
          className="form-control table__search table__search-input mb-0"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          placeholder="From Date"
          maxLength="10"
        />
        <Input
          type="submit"
          className="btn-blue btn-w text-light table__search table__search-input mb-0"
          value="Filter"
        />
        {(searchInput || date) && (
          <Input
            type="reset"
            className="btn-w text-light table__search table__search-input mb-0"
            value="Clear"
            onClick={handleClear}
          />
        )}
      </Form>
    </div>
  );
};

export default ProcessingLogTableSearch;
