import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InfoOutlineIcon from "mdi-react/InfoOutlineIcon";
import { Container, UncontrolledTooltip } from "reactstrap";

import { Table } from "@atoms";
import { formatCurrency } from "@containers/components/helpers";

const PercentPerYear = ({ value }) => {
  const isZeroPercent = value === 0;
  if (value || isZeroPercent) {
    return (
      <div className="tooltip_wrapper">
        <div
          className={`text-center fs-small ${isZeroPercent ? "" : "mb-3 pt-1"}`}
        >
          {value}% interest per year
          {isZeroPercent && (
            <div className="font-weight-normal">(worst-case scenario)</div>
          )}
        </div>
        {!isZeroPercent && (
          <>
            <InfoOutlineIcon id={`lifeTimeIncome-${value}`} size={15} />
            <UncontrolledTooltip
              placement="bottom"
              target={`lifeTimeIncome-${value}`}
            >
              This percent could be higher or lower depending on the actual
              performance of the crediting strategies throughout the lifetime of
              the annuity.
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  }
  return null;
};

const commonProps = {
  size: 250,
  alignCenter: true,
};

const column = [
  {
    id: "age",
    header: "",
    cell: (tableRow) => {
      const { id, original } = tableRow.row || {};
      const { age } = original || {};
      const thirtyYearsLater = id === "1";
      return (
        <div className="w-100 pl-3 font-weight-bold">
          Age {age}
          {thirtyYearsLater ? (
            <small className="d-block">(30 years later)</small>
          ) : null}
        </div>
      );
    },
  },
  {
    id: "twoPercentPerYear",
    header: <PercentPerYear value={2} />,
    ...commonProps,
    cell: (tableRow) => {
      const { twoPercentPerYear } = tableRow.row.original || {};
      return (
        <Box
          variant="outlined"
          p={1}
          className="border border-secondary text-right mr-2 text-dark bg-inactive"
          borderRadius="borderRadius"
        >
          {formatCurrency(twoPercentPerYear)}
        </Box>
      );
    },
  },
  {
    id: "zeroPercentPerYear",
    header: <PercentPerYear value={0} />,
    ...commonProps,
    cell: (tableRow) => {
      const { zeroPercentPerYear } = tableRow.row.original || {};
      return (
        <Box
          variant="outlined"
          p={1}
          className="border border-secondary text-right text-muted bg-inactive"
          borderRadius="borderRadius"
        >
          {formatCurrency(zeroPercentPerYear)}
        </Box>
      );
    },
  },
];

const RightSideIncomeProjections = ({ form, incomeCalculationData }) => {
  const { getValues } = form || {};

  const {
    growthMonthly,
    growthMonthlyEnd,
    zeroGrowthMonthly,
    zeroGrowthMonthlyEnd,
  } = incomeCalculationData || {};

  const { participantAge } = getValues();

  const data = [
    {
      age: `${participantAge}`,
      twoPercentPerYear: growthMonthly,
      zeroPercentPerYear: zeroGrowthMonthly,
    },
    {
      age: `${parseInt(participantAge || 0) + 30}`,
      twoPercentPerYear: growthMonthlyEnd,
      zeroPercentPerYear: zeroGrowthMonthlyEnd,
    },
  ];

  return (
    <Container className="p-0">
      <div className="bg-income_projection">
        <div className="text-center w-75 ml-auto">
          <Typography className="font-weight-bold">
            Projected Monthly Income
          </Typography>
          <Typography variant="caption">
            (assuming no additional contributions)
          </Typography>
        </div>
      </div>
      <Table columns={column} data={data} hidePagination />
    </Container>
  );
};

export default RightSideIncomeProjections;
