import React, { useState } from "react";

import TrendingDownIcon from "mdi-react/TrendingDownIcon";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { BarChart, Bar, Cell, ResponsiveContainer } from "recharts";

const data = [
  { id: 0, name: "Page A", pv: 255 },
  { id: 1, name: "Page B", pv: 452 },
  { id: 2, name: "Page C", pv: 154 },
  { id: 3, name: "Page D", pv: 85 },
  { id: 4, name: "Page E", pv: 545 },
  { id: 5, name: "Page F", pv: 438 },
  { id: 6, name: "Page G", pv: 523 },
  { id: 7, name: "Page H", pv: 226 },
  { id: 8, name: "Page I", pv: 112 },
  { id: 9, name: "Page J", pv: 101 },
];

const NewUsers = () => {
  const { t } = useTranslation("common");
  const [activeIndex, setActiveIndex] = useState(0);
  const activeItem = data[activeIndex];

  const handleClick = (item) => {
    const index = data.indexOf(item.payload);
    setActiveIndex(index);
  };

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          <div className="card__title">
            <h5 className="bold-text">
              {t("online_marketing_dashboard.new_users")}
            </h5>
          </div>
          <div className="dashboard__total">
            <TrendingDownIcon className="dashboard__trend-icon" />
            <p className="dashboard__total-stat">{activeItem.pv}</p>
            <div className="dashboard__chart-container">
              <ResponsiveContainer height={50}>
                <BarChart data={data}>
                  <Bar dataKey="pv" onClick={handleClick}>
                    {data.map((entry, index) => (
                      <Cell
                        key={entry.id}
                        cursor="pointer"
                        fill={index === activeIndex ? "#4ce1b6" : "#c88ffa"}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default NewUsers;
