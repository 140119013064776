import { useCallback, useEffect, useMemo, useState } from "react";

import { Container } from "reactstrap";

import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";

import WorkflowsForm from "./components/WorkflowsForm";

const queryParams = [
  "searchInput",
  "wfType",
  "statusInput",
  "fromDate",
  "toDate",
  "from",
  "sortWFNumber",
  "sortWType",
  "sortWDate",
  "sortReAccountNumber",
  "pageSize",
];

const EditWorkflow = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { get, isPending } = useApiFetch();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const [singleWorkflow, setSingleWorkflow] = useState({});

  const { from: page, ...restParams } = getQueryParams(queryParams);

  const getSingleWorkflow = useCallback(async () => {
    const data = await get(`workflows/${id}`);
    setSingleWorkflow(data);
  }, [id]);

  useEffect(() => {
    getSingleWorkflow();
  }, [getSingleWorkflow]);

  const commonPath = useMemo(
    () =>
      buildQueryParams({
        page,
        ...restParams,
      }),
    [page, restParams]
  );

  const goBackUrl = useMemo(() => `/workflows?${commonPath}`, [commonPath]);

  const gotoWorkflowTypeIdUrl = useMemo(
    () =>
      `/workflows/workflowdetail${singleWorkflow?.workflowTypeID}/${id}?${commonPath}`,
    [commonPath, id, singleWorkflow?.workflowTypeID]
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Workflow Detail</h3>
      <FormSpinner requesting={isPending} />
      <WorkflowsForm
        id={id}
        data={singleWorkflow}
        goBackUrl={goBackUrl}
        gotoWorkflowTypeIdUrl={gotoWorkflowTypeIdUrl}
      />
    </Container>
  );
};

export default EditWorkflow;
