import { useState, useImperativeHandle, forwardRef } from "react";

import { Modal } from "@atoms";
import useApiFetch from "@hooks/useApiFetch";

const WorkflowModal = forwardRef(({ id, goBackUrl, workflowTypeID }, ref) => {
  const { post } = useApiFetch();

  const [resubmitModal, setResubmitModal] = useState(false);
  const [failModal, setFailModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);

  const toggleFailModal = () => setFailModal(!failModal);
  const toggleResubmitModal = () => setResubmitModal(!resubmitModal);
  const toggleAlertModal = () => setAlertModal(!alertModal);

  const handleResubmit = async () => {
    toggleResubmitModal();
    const workflowIdPath =
      workflowTypeID === 1006 || workflowTypeID === 1007
        ? "10061007"
        : workflowTypeID;

    const url = `workflows/resubmit/${workflowIdPath}`;
    const queryString =
      workflowTypeID === 1004 ? `?originalWorkflowID=${id}` : "";
    const payload = workflowTypeID !== 1004 ? { originalWorkflowID: id } : {};
    await post(url + queryString, payload, { redirectUrl: goBackUrl });
  };

  const handleFailModalCancel = async () => {
    toggleFailModal();
    await post(`workflows/cancel/${id}`, null, { redirectUrl: goBackUrl });
  };

  const handleClearAlertModal = async () => {
    toggleAlertModal();
    await post(`workflows/${id}/clearworkflowalert`, null, {
      redirectUrl: goBackUrl,
    });
  };

  useImperativeHandle(ref, () => ({
    toggleFailModal,
    toggleResubmitModal,
    toggleAlertModal,
  }));

  const failModalButtons = [
    {
      name: "Cancel",
      color: "danger",
      onClick: toggleFailModal,
    },
    {
      name: "Confirm",
      color: "primary",
      onClick: handleFailModalCancel,
    },
  ];

  const resubmitModalButtons = [
    {
      name: "Cancel",
      color: "danger",
      onClick: toggleResubmitModal,
    },
    {
      name: "Confirm",
      color: "primary",
      onClick: handleResubmit,
    },
  ];

  const alertModalButtons = [
    {
      name: "Cancel",
      color: "danger",
      onClick: toggleAlertModal,
    },
    {
      name: "Confirm",
      color: "primary",
      onClick: handleClearAlertModal,
    },
  ];

  return (
    <div>
      <Modal
        isModalOpen={failModal}
        setIsModalOpen={setFailModal}
        content="Are you sure you wish to permanently cancel this Workflow?"
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={failModalButtons}
      />
      <Modal
        isModalOpen={resubmitModal}
        setIsModalOpen={setResubmitModal}
        content="Are you sure you wish to resubmit this Workflow?"
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={resubmitModalButtons}
      />
      <Modal
        isModalOpen={alertModal}
        setIsModalOpen={setAlertModal}
        content="Are you sure you wish to clear the Alert?"
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={alertModalButtons}
      />
    </div>
  );
});

export default WorkflowModal;
