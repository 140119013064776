import { Container } from "reactstrap";

import RKChannelForm from "../RKChannelForm/RKChannelForm";

const data = {
  RKChannelName: "",
  acceptNewRecordKeepers: true,
  acceptNewPlans: true,
  active: true,
  type: "add",
};

const AddRKChannel = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Add RK Channel</h3>
    <RKChannelForm data={data} />
  </Container>
);

export default AddRKChannel;
