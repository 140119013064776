import { useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, Select, FormFooterActions } from "@atoms";
import { msgs } from "@config/conf";
import { filterOptions, sanitizeValue } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useToast from "@hooks/useToast";
import { emailPattern } from "@shared/helpers";

const carrierFormFields = [
  {
    label: "Carrier Name",
    name: "carrierName",
    maxLength: 200,
    isRequired: true,
  },
  {
    label: "Carrier Code",
    name: "extCarrierCode",
    maxLength: 200,
    isRequired: true,
  },
  {
    label: "Contact Name",
    name: "contactName",
    maxLength: 100,
  },
  {
    label: "Contact Phone",
    name: "contactPhone",
    maxLength: 50,
  },
  {
    label: "Contact Email",
    name: "contactEmail",
    maxLength: 100,
    type: "email",
  },
  {
    label: "Bank Name",
    name: "bankName",
    maxLength: 200,
  },
  {
    label: "Routing Number",
    name: "routingNumber",
    maxLength: 50,
  },
  {
    label: "Account Number",
    name: "accountNumber",
    maxLength: 50,
  },
];

const CarrierForm = ({ carriers, redirectUrl }) => {
  const { control, reset, handleSubmit } = useForm({
    mode: "onChange",
  });
  const { get, post, put } = useApiFetch();
  const { validateRequiredField } = useToast();

  const [endPointSet, setEndPointSet] = useState([]);

  const getEndPointSet = async () => {
    const data = await get("endpointsets/get");
    const filteredEndPointSet = data?.data?.filter((item) =>
      Boolean(item.endpointSetID)
    );
    setEndPointSet(filteredEndPointSet);
  };

  useEffect(() => {
    getEndPointSet();
  }, []);

  useEffect(() => {
    reset({ ...carriers });
  }, [reset, carriers]);

  const endPointSetOptions = useMemo(
    () => filterOptions(endPointSet, "endpointSetID", "endpointSetName"),
    [endPointSet]
  );

  const requiredProps = {
    control,
    required: true,
    rules: { required: msgs.fieldError },
  };

  const onSubmit = (values) => {
    const { carrierName, extCarrierCode, endpointSetID } = values || {};

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const isValid = validateRequiredField({
      "Carrier Name": carrierName,
      "Carrier Code": extCarrierCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      endpointSetID: Number(endpointSetID),
    };

    if (carriers?.carrierID) {
      put(`carriers/${carriers?.carrierID}`, payloadValues, { redirectUrl });
    } else {
      post("carriers/create", payloadValues, { redirectUrl });
    }
  };

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          {carrierFormFields?.map(
            ({ label, maxLength, name, isRequired, type }) => {
              const commonProps = isRequired
                ? { ...requiredProps }
                : { control };

              if (type === "email") {
                return (
                  <Input
                    control={control}
                    key={name}
                    label={label}
                    name={name}
                    maxLength={maxLength}
                    rules={{
                      pattern: {
                        value: emailPattern,
                        message: "Email is invalid.",
                      },
                    }}
                  />
                );
              }
              return (
                <Input
                  {...commonProps}
                  key={name}
                  label={label}
                  name={name}
                  maxLength={maxLength}
                />
              );
            }
          )}
          <Select
            name="endpointSetID"
            label="Endpoint Set"
            options={endPointSetOptions}
            isLoading={endPointSet?.requesting}
            errorMessage={endPointSet?.err}
            {...requiredProps}
          />
          <FormFooterActions
            linkProps={[{ path: redirectUrl }]}
            buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default CarrierForm;
