import React from "react";

import PropTypes from "prop-types";
// import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Table } from "reactstrap";

import CarrierProductsTableData from "./CarrierProductsTableData";

const { tableRowsData } = CarrierProductsTableData();

const CarrierProductsTable = ({ carrierId }) => (
  // <Col md={12} lg={12}>
  <Card>
    <CardBody>
      {/* <div className="card__title">
          <h5 className="bold-text">{t('tables.basic_tables.responsive_table')}</h5>
          <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5>
        </div> */}
      <Table responsive className="table--bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Product Type</th>
            <th>Product Name</th>
            <th>Ext Product Id</th>
            <th>Min Initial Purchase</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableRowsData.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.productType}</td>
              <td>{item.productName}</td>
              <td>{item.extProductId}</td>
              <td>{item.minInitialPurchase}</td>
              <td>{item.active ? "Yes" : "No"}</td>
              {/* <td><Badge color={item.status_resp}>{item.badge_resp}</Badge></td> */}
              <td>
                <Link to={`/carriers/${carrierId}/products/${item.id}/edit`}>
                  <Badge color="primary">View / Edit</Badge>
                </Link>{" "}
                <Link
                  to={`/carriers/${carrierId}/products/${item.id}/subaccounts`}
                >
                  <Badge color="success">Subaccounts</Badge>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
  // </Col>
);

CarrierProductsTable.propTypes = {
  carrierId: PropTypes.number.isRequired,
};

export default CarrierProductsTable;
