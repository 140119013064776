import React, { useState } from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import i18n from "i18next";
import "bootstrap/dist/css/bootstrap.css";
// import "firebase/auth";
import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import { connect, Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "../../scss/app.scss";

import AuthProvider from "@contexts/AuthContext";
import { connectTheme } from "@contexts/Context";

import { config as i18nextConfig } from "../../translations";

import IdleTimerContainer from "./IdleTimerContainer";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import store from "./store";
// import history from "../../utils/history";

i18n.init(i18nextConfig);

const ThemeComponent = ({ children, themeName }) => {
  const theme = createTheme({
    palette: {
      type: themeName === "theme-dark" ? "dark" : "light",
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const ConnectedThemeComponent = connect((state) => ({
  themeName: state.theme.className,
}))(ThemeComponent);

const App = () => {
  const [isLight, setIsLight] = useState(true);

  // useEffect(() => {
  //   firebase.initializeApp(firebaseConfig);
  // }, []);

  // const onRedirectCallbackAuth0 = (appState) => {
  //   window.history.replaceState(
  //     {},
  //     document.title,
  //     appState && appState.targetUrl
  //       ? appState.targetUrl
  //       : window.location.pathname
  //   );
  // };

  // console.log(history);

  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <AuthProvider>
          <BrowserRouter>
            <I18nextProvider i18n={i18n}>
              {/* <LoadScript
                  googleMapsApiKey="" /* Paste your Google Maps Api Key here
                > */}
              <ScrollToTop>
                <connectTheme.Provider value={{ isLight, setIsLight }}>
                  <ConnectedThemeComponent>
                    <IdleTimerContainer />
                    <Router />
                  </ConnectedThemeComponent>
                </connectTheme.Provider>
              </ScrollToTop>
              {/* </LoadScript> */}
            </I18nextProvider>
          </BrowserRouter>
        </AuthProvider>
      </SnackbarProvider>
    </Provider>
  );
};

export default App;
