const CarrierProductsTableData = () => {
  const header = [
    { id: 1, title: "#" },
    { id: 2, title: "First Name" },
    { id: 3, title: "Last Name" },
    { id: 4, title: "Username" },
    { id: 5, title: "Status" },
  ];

  const headerResponsive = [
    { id: 1, title: "#" },
    { id: 2, title: "Product Type" },
    { id: 3, title: "Product Name" },
    { id: 4, title: "Ext Product Id" },
    { id: 5, title: "Min Initial Purchase" },
    { id: 6, title: "Active" },
    { id: 7, title: "" },
  ];

  const rows = [
    {
      id: 1,
      productType: "Maria",
      productName: "Morisson",
      extProductId: "@dragon",
      minInitialPurchase: 21,
      active: true,
    },
    {
      id: 2,
      productType: "Bobby",
      productName: "Browm",
      extProductId: "@boboby",
      minInitialPurchase: 34,
      active: true,
    },
    {
      id: 3,
      productType: "Alexander",
      productName: "Medinberg",
      extProductId: "@medinberg",
      minInitialPurchase: 93,
      active: true,
    },
    {
      id: 1,
      productType: "Vera",
      productName: "Lori",
      extProductId: "@lori",
      minInitialPurchase: 23,
      active: true,
    },
  ];

  const carrierProductsTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
    tableRowsData: rows,
  };
  return carrierProductsTableData;
};

export default CarrierProductsTableData;
