import React from "react";

import PropTypes from "prop-types";
import { Container } from "reactstrap";

import CustodiansPlansTable from "./components/CustodiansPlansTable";

const CustodiansPlans = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return (
    <Container>
      <h3 className="mb-3">Plans</h3>

      <CustodiansPlansTable custId={id} />
    </Container>
  );
};

CustodiansPlans.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default CustodiansPlans;
