import { Input } from "@atoms";

const accountInformationFields = [
  { label: "prefix", name: "prefix" },
  { label: "First Name", name: "firstName" },
  { label: "Middle Name", name: "middleName" },
  { label: "Last Name", name: "lastName" },
  { label: "Suffix", name: "suffix" },
  { label: "Date of Birth", name: "dob" },
  { label: "Gender", name: "gender" },
  { label: "Email Address", name: "emailAddress" },
  { label: "Primary Phone", name: "primaryPhone" },
  { label: "Mobile Phone", name: "mobilePhone" },
  { label: "Notes", name: "notes", type: "textarea" },
  { label: "Account Created", name: "accountCreatedON" },
  { label: "Sent to Custodian", name: "custodianAccountDate" },
];

const AccountInformation = ({ control }) => (
  <div>
    {accountInformationFields?.map(({ label, name, type }) => {
      const isTextareaInput = type === "textarea";
      return (
        <Input
          key={label}
          className="mb-2"
          control={control}
          type={isTextareaInput ? "textarea" : "text"}
          name={name}
          isReadOnly={!isTextareaInput}
          label={label}
          maxLength={!isTextareaInput ? 2000 : ""}
        />
      );
    })}
  </div>
);

export default AccountInformation;
