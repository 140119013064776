import React from "react";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const ServiceCenter = () => (
  <>
    <Grid container direction="row" spacing={5}>
      <Grid item xs={10} sm={6}>
        <Typography variant="h6" style={{ display: "inline-block" }}>
          Need Help?
        </Typography>
        <Typography variant="caption" component="div">
          Have a question about the annuity or the lifetime income guarantee?
        </Typography>
        <Typography variant="h6" component="div">
          Call (888) 909-9380
        </Typography>
        <Typography variant="caption" component="div">
          Mon - Fri 8:30am to 5:30pm CT
        </Typography>
      </Grid>
    </Grid>
  </>
);

export default ServiceCenter;
