import { createContext, useContext, useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import { Redirect } from "react-router";

import useLogout from "@hooks/useLogout";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [payload, setPayload] = useState(null);
  const [token, setToken] = useState("");
  const {
    logout: logoutUser,
    logoffAnnuitantToken,
    logoutGenericAnnuitant,
  } = useLogout();

  const annuityToken = sessionStorage.getItem("annuityAuthToken") || "";
  const path = window.location.pathname;
  const isViewGenericUserAccountSummary = path.includes(
    "/View/GenericUserAccount/Summary"
  );

  const getToken = async () => {
    setToken(
      (await localStorage.getItem("authToken"))
        ? await localStorage.getItem("authToken")
        : null
    );

    setPayload(
      (await localStorage.getItem("authToken"))
        ? jwt_decode(await localStorage.getItem("authToken"))
        : token
          ? jwt_decode(token)
          : null
    );
    return localStorage.getItem("authToken") ? "" : <Redirect to="/" />;
  };

  useEffect(() => {
    getToken();
  }, []);

  const logout = () => {
    logoutUser();
    setPayload("");
    setToken("");
  };

  const annuityLogout = () => {
    isViewGenericUserAccountSummary
      ? logoutGenericAnnuitant()
      : logoffAnnuitantToken();
    sessionStorage.removeItem("annuityAuthToken");
    sessionStorage.removeItem("activeTab");
  };

  const value = {
    user: payload,
    setPayload,
    token,
    setToken,
    logout,
    annuityToken,
    annuityLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
