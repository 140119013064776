import { useMemo } from "react";

import Input from "reactstrap/lib/Input";

import { TableSearchInput } from "@atoms";

const commonClassName =
  "btn-w text-light table__search table__search-input mb-0";

const RecordKeeperTableSearch = ({ form, setSubmitSearch }) => {
  const { control, setValue, watch } = form;
  const { searchInput } = watch();

  const handleClear = () => {
    setSubmitSearch(true);
    setValue("searchInput", "");
  };

  const handleClick = () => {
    setSubmitSearch(true);
  };

  const buttonProps = useMemo(
    () => [
      {
        type: "submit",
        className: `btn-blue ${commonClassName}`,
        value: "Filter",
        onClick: handleClick,
      },
      ...(searchInput
        ? [
            {
              type: "reset",
              className: commonClassName,
              value: "Clear",
              onClick: handleClear,
            },
          ]
        : []),
    ],
    [searchInput]
  );

  return (
    <div className="table__search mb-0 flex align-items-center form-inline w-100">
      <TableSearchInput
        control={control}
        className="w-100"
        name="searchInput"
        placeholder="Recordkeeper Name"
      />
      {buttonProps?.map((props) => (
        <Input key={props?.value} {...props} />
      ))}
    </div>
  );
};

export default RecordKeeperTableSearch;
