import { useCallback, useEffect, useMemo, useState } from "react";

import { Container } from "reactstrap";

import { sanitizeValue } from "@containers/components/helpers";
import { FormSpinner } from "@containers/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import useToast from "@hooks/useToast";

import UserForm from "../UsersForm/UserForm";

const queryParams = [
  "from",
  "search",
  "sortUserType",
  "sortUserName",
  "sortStatus",
  "pageSize",
];

const Edituser = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { get, put, isPending } = useApiFetch();
  const [userDetail, setUserDetail] = useState({});

  const { validateRequiredField } = useToast();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: fromPage, ...restParams } = getQueryParams(queryParams);

  const navigateToUsers = useMemo(() => {
    const params = buildQueryParams({ page: fromPage, ...restParams });

    return `/users?${params}`;
  }, [fromPage, restParams]);

  const getUser = useCallback(async () => {
    const response = await get(`users/${id}`);
    setUserDetail(response?.data);
  }, [id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handelEditUser = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { userName, loginName } = values || {};

    const isValid = validateRequiredField({
      "User Name": userName,
      "Login Name": loginName,
    });

    if (!isValid) {
      return;
    }
    put(`users/${id}`, values, { redirectUrl: navigateToUsers });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">User Detail</h3>
      <FormSpinner requesting={isPending} />
      <UserForm
        data={userDetail}
        id={id}
        redirectUrl={navigateToUsers}
        onSubmit={handelEditUser}
      />
    </Container>
  );
};

export default Edituser;
