import axios from "axios";

import { conf, msgs } from "@config/conf";

import useToast from "./useToast";

const apiClient = axios.create({
  baseURL: conf.serverURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const { error, authError } = msgs || {};

const useLogout = () => {
  const { annuityAuthToken, reAccountNumber } = sessionStorage || {};
  const { authToken, loginName } = localStorage || {};
  const { showErrorToast } = useToast();

  const post = async (url, payload) => {
    try {
      const response = await apiClient.post(url, payload);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (err.response?.status === 401) {
        showErrorToast(authError);
      } else showErrorToast(err?.response?.data?.message || error);
    }
  };

  const handleLogoutResponse = () => {
    const link = document.createElement("a");
    const host = window.location.host.toLowerCase();
    link.href = `${window.location.protocol}//${host}/ViewAccountLogOut`;
    link.click();
  };

  const logoffAnnuitantToken = async () => {
    const credentials = {
      token: annuityAuthToken,
      loginName: "annuitysystemuser",
      userType: "Annuitant",
    };
    const logoutResponse = await post("logout", credentials);
    if (logoutResponse?.requestLogID) {
      handleLogoutResponse();
    }
  };

  const logoutGenericAnnuitant = async () => {
    const credentials = {
      token: annuityAuthToken,
      loginName: "GenericAnnuitantUser",
      userType: "Annuitant",
      reAccountNumber,
    };
    const logoutResponse = await post(
      "logoutGenericAnnuitantUser",
      credentials
    );
    if (logoutResponse?.requestLogID) {
      handleLogoutResponse();
    }
  };

  const logout = async () => {
    const credentials = {
      token: authToken,
      loginName,
      userType: "admin",
    };

    await post("logout", credentials);
    localStorage.removeItem("authToken");
  };

  return { logout, logoffAnnuitantToken, logoutGenericAnnuitant };
};

export default useLogout;
