import { useCallback, useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { formatCurrency } from "@containers/components/helpers";

import AnnuityDocumentsPage from "./AnnuityDocumentsPage";

const Summary = (props) => {
  const { data, tabClick } = props;

  const total = useMemo(
    () =>
      data?.reduce(
        (res, { policyAccumulationValue }) => (res += policyAccumulationValue),
        0
      ),
    [data]
  );

  const handleTaxTab = useCallback(
    (index) => {
      const tabNumber = index + 1;
      if (tabNumber) {
        tabClick(tabNumber);
      }
    },
    [tabClick]
  );

  return (
    <Grid container spacing={3} className="annuity_summary_wrapper">
      <Grid item xs={12} sm={6}>
        <Paper className="annuity_provider">
          <Typography className="text-white font-weight-bold">
            Allianz Annuity
          </Typography>

          <div>
            <Typography className="text-white font-weight-bold">
              Total Accumulation Value
            </Typography>

            <Typography className="text-white font-weight-bold text-right">
              <span className="MuiTypography-h6">
                {formatCurrency(total).toString().split(".")[0]}
              </span>
              <sup className="sc-fotOHu dfigil">
                {formatCurrency(total).toString().split(".")[1]}
              </sup>
            </Typography>
          </div>
        </Paper>

        {data?.map((item, index) => {
          const {
            reAccountNumber,
            taxCode,
            policyNumber,
            policyAccumulationValue,
          } = item;

          return (
            <Grid
              key={reAccountNumber}
              container
              spacing={1}
              className="border-bottom border-dark mt-2"
            >
              <Grid item xs={6} sm={4}>
                <span className="pl-3 align-middle">{taxCode}</span>
              </Grid>
              <Grid item xs={6} sm={4}>
                <button onClick={() => handleTaxTab(index)} className="btn">
                  View Policy Info
                  <div>{policyNumber}</div>
                </button>
              </Grid>

              <Grid item xs={6} sm={4} className="pl-5">
                <div className="container">Accumulation Value</div>
                <div className="container font-weight-bold">
                  <span className="MuiTypography-h6">
                    {
                      formatCurrency(policyAccumulationValue)
                        .toString()
                        .split(".")[0]
                    }
                  </span>
                  <sup className="sc-fotOHu dfigil">
                    {
                      formatCurrency(policyAccumulationValue)
                        .toString()
                        .split(".")[1]
                    }
                  </sup>
                </div>
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper className="annuity_provider">
          <Typography className="text-white font-weight-bold">
            Annuity Provider/Product
          </Typography>
        </Paper>
        <AnnuityDocumentsPage />
        {/* <Grid item xs={6} sm={12} >
              <a className="button button-primary" style={{float: 'right', marginRight:'20px', verticalAlign:'middle', backgroundColor:'#7199bb', borderRadius: '5px', display: 'block', width: '25px', height: '25px', textAlign: 'center', color: 'white', fontWeight: 'bold'}} target="_blank" rel="noreferrer"  href="https://www.allianzlife.com/what-we-offer/annuities/fixed-index-annuities/lifetime-income-plus-ijoin">{String.fromCharCode(8594)}</a>
            </Grid>          */}
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Summary;
