export const withdrawalMethodOptions = [{ label: "Gross", value: "Gross" }];

export const workflowTypeOptions = [
  { label: "Withdrawal", value: "Withdrawal" },
  {
    label: "Premium Payment",
    value: "Premium Payment",
  },
];

export const withdrawalTypeOptions = [
  { label: "Asset Transfer", value: "Asset Transfer" },
  { label: "Correction", value: "Correction" },
  { label: "Excess Contribution", value: "Excess Contribution" },
  { label: "In Service", value: "In Service" },
  { label: "Termination", value: "Termination" },
  { label: "QDRO", value: "QDRO" },
  { label: "RMD", value: "RMD" },
  { label: "Service Charge", value: "Service Charge" },
];
