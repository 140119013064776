import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Badge } from "reactstrap";

import { Table, Spinner, Modal, ExportToCsv, TableDashboard } from "@atoms";
import { pagination } from "@config/conf";
import { formatCurrency, getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useCsvExportButton from "@hooks/useCsvExportButton";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate } from "@lib/date";
import { formatFixedDigits } from "@lib/number";

import PurchasesWithdrawalsSearch from "./PurchasesWithdrawalsSearch";

const queryParam = [
  "search",
  "status",
  "custodian",
  "pageSize",
  "sortWfNumber",
  "sortWfStartDate",
  "sortWfStatus",
  "page",
  "dateRangeInput",
  "effectiveStartDate",
  "effectiveEndDate",
];

const pandwWorkflowColumn = [
  {
    id: "reAccountNumber",
    header: "RE Account #",
    accessorKey: "reAccountNumber",
  },
  {
    id: "result",
    header: "Result",
    accessorKey: "result",
  },
  {
    id: "activityDate",
    header: "Posted",
    accessorFn: (row) => formatDate(row?.activityDate, "MM/DD/YY"),
  },
  {
    id: "activityAmount",
    header: "Amount",
    cell: (tableRow) => {
      const { activityAmount } = tableRow.row.original || {};
      return (
        <>
          {activityAmount < 0 ? (
            <span className="text-danger">
              {formatCurrency(Math.abs(activityAmount), 2)}
            </span>
          ) : (
            formatCurrency(activityAmount, 2)
          )}
        </>
      );
    },
  },
  {
    id: "childWorkflowNumber",
    header: "WF#",
    accessorFn: (row) =>
      row?.childWorkflowNumber
        ? formatFixedDigits(row?.childWorkflowNumber, 12)
        : "-",
  },
  {
    id: "workflowStatus",
    header: "Status",
    accessorFn: (row) =>
      `${row?.childWorkflowStatus || ""} / ${
        row?.childWorkflowSubStatus || ""
      }`,
  },
];

const PandWWorkflowLogTable = (subTableProps) => {
  const { workflowData = [] } = subTableProps.row.original || {};
  return (
    <Table columns={pandwWorkflowColumn} data={workflowData} hasNestedTable />
  );
};

const initialModalState = {
  rejectModal: false,
  submitModal: false,
  confirmSubmitModal: false,
  alertModal: false,
  exportingId: "",
  wfID: "",
};

const PurchasesWithdrawals = () => {
  const { post, get, isPending } = useApiFetch();
  const history = useHistory();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const {
    search,
    status: purchaseAndWithdrawalsStatus,
    custodian: custodians,
    pageSize: pageSizePath,
    sortWfNumber,
    sortWfStartDate,
    sortWfStatus,
    page,
    dateRangeInput,
    effectiveStartDate,
    effectiveEndDate,
  } = getQueryParams(queryParam);

  const form = useForm({
    defaultValues: {
      searchWorkflow: search,
      workflowStatus: purchaseAndWithdrawalsStatus || "In Progress",
      custodianID: custodians,
      dateRange: dateRangeInput || "AllEntries",
      effectiveStartDate,
      effectiveEndDate,
    },
  });

  const [pageSize, setPageSize] = useState(pageSizePath || pagination.length);
  const [submitSearch, setSubmitSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [tableState, setTableState] = useState(initialModalState);
  const [fetchData, setFetchData] = useState({});

  const { offset, pageNumber } = getPagination(pageSize, page);
  const { recordCount = 0, data = [] } = fetchData || {};

  const {
    csvClickRef,
    csvData: { data: exportData = [] },
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const { getValues } = form;
  const {
    searchWorkflow,
    workflowStatus,
    custodianID,
    dateRange,
    effectiveStartDate: startDate,
    effectiveEndDate: endDate,
  } = getValues();

  const {
    alertModal,
    confirmSubmitModal,
    exportingId,
    rejectModal,
    submitModal,
    wfID,
  } = tableState;

  const orderBy = useMemo(() => {
    if (sortWfNumber) {
      return `Workflows.WorkflowNumber ${sortWfNumber.toUpperCase()}`;
    }
    if (sortWfStartDate) {
      return `Workflows.WorkflowStartDate ${sortWfStartDate.toUpperCase()}, Workflows.WorkflowNumber DESC`;
    }
    if (sortWfStatus) {
      return `Workflows.Status ${sortWfStatus.toUpperCase()}, Workflows.WorkflowNumber DESC`;
    }
    return "";
  }, [sortWfNumber, sortWfStartDate, sortWfStatus]);

  const updateState = useCallback((field, value) => {
    setTableState((state) => ({
      ...state,
      [field]: value,
    }));
  }, []);

  const handleCsvExport = useCallback((workflowID) => {
    updateState("exportingId", workflowID);
    getExport(`operations/pandw/${workflowID}`);
  }, []);

  const queryParams = buildQueryParams({
    search: searchWorkflow,
    status: workflowStatus,
    custodian: custodianID,
    sortWfNumber,
    sortWfStartDate,
    sortWfStatus,
    dateRangeInput: dateRange,
    effectiveStartDate: startDate,
    effectiveEndDate: endDate,
    pageSize,
  });

  const getPurchaseAndWithdrawals = useCallback(async () => {
    const params = buildQueryParams({
      Limit: pageSize,
      Offset: offset,
      OrderBy: orderBy,
      search: searchWorkflow,
      status: workflowStatus,
      custodianID,
      dateRangeRequested: dateRange,
      effectiveStartDate: startDate,
      effectiveEndDate: endDate,
    });

    const response = await get(`operations/pandw?${params}`);
    setFetchData(response);
  }, [offset, orderBy, pageSize, submitSearch, refresh]);

  useEffect(() => {
    if (submitSearch) {
      history.push(`/purchases-and-withdrawals?page=1&${queryParams}`);
    } else {
      getPurchaseAndWithdrawals();
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [getPurchaseAndWithdrawals, refresh, submitSearch]);

  const handleAction = useCallback(
    (workflowID, actionType) => {
      if (isPending) {
        updateState("alertModal", true);
        return false;
      }
      updateState("wfID", workflowID);
      if (actionType === "submit") {
        updateState("submitModal", !submitModal);
      } else if (actionType === "reject") {
        updateState("rejectModal", !rejectModal);
      }
    },
    [isPending, rejectModal, submitModal]
  );

  const pandwColumn = useMemo(
    () => [
      {
        id: "expanded-chevron",
        header: "",
        size: 0,
        cell: (tableRow) => {
          const { expandedRowIds, row } = tableRow || {};
          const className = `lnr ${
            expandedRowIds?.includes(row.id)
              ? "lnr-chevron-down"
              : "lnr-chevron-right"
          }`;
          return (
            <div className="expanded_chevron">
              <i className={className} />
            </div>
          );
        },
      },
      {
        id: "wfNumber",
        enableSorting: true,
        header: "WF#",
        accessorFn: (row) => formatFixedDigits(row?.workflowNumber, 12),
      },
      {
        id: "wfStartDate",
        enableSorting: true,
        header: "Start Date",
        accessorFn: (row) => formatDate(row?.workflowStartDate, "MM/DD/YY"),
      },
      {
        id: "wfStatus",
        header: "Status",
        accessorFn: (row) => `${row?.status} / ${row?.subStatus}`,
      },
      {
        id: "custodianName",
        header: "Custodian",
        accessorKey: "custodianName",
      },
      {
        id: "totalAcceptAndReject",
        header: <span className="column-center">A / R</span>,
        cell: (tableRow) => {
          const { totAccepted, totRejected } = tableRow?.row?.original || {};
          return (
            <div className="column-center">
              {`${totAccepted || 0} / ${totRejected || 0}`}
            </div>
          );
        },
      },
      {
        id: "totalPurchaseAndWithdrawals",
        header: <span className="column-end">P / W</span>,
        cell: (tableRow) => {
          const { totPurchases, totWithdrawals } =
            tableRow?.row?.original || {};
          return (
            <div className="column-end">
              {formatCurrency(Math.abs(totPurchases), 2)} /{" "}
              <span className="text-danger">
                ({formatCurrency(Math.abs(totWithdrawals), 2)})
              </span>
            </div>
          );
        },
      },
      {
        id: "actions",
        header: "",
        cell: (tableRow) => {
          const { status, subStatus, totAccepted, workflowID, workflowNumber } =
            tableRow.row.original || {};
          const isExporting = exportingId === workflowID;

          return (
            <div className="flex justify-content-end pr-2 my-1">
              {status === "In Progress" && subStatus === "Accepted" ? (
                <>
                  <Badge
                    color="danger"
                    className="pointer mr-1 h-max-content"
                    onClick={() => handleAction(workflowID, "reject")}
                  >
                    Reject
                  </Badge>
                  {totAccepted > 0 ? (
                    <Badge
                      color="success"
                      className="pointer mr-1 h-max-content"
                      onClick={() => handleAction(workflowID, "submit")}
                    >
                      Submit
                    </Badge>
                  ) : null}
                </>
              ) : null}
              <ExportToCsv
                csvRef={csvClickRef}
                fileName={formatFixedDigits(workflowNumber, 12)}
                getCsvExport={() => handleCsvExport(workflowID)}
                loadingResponse={isExporting && loadingResponse}
                tableCellSpecifier={{ hasExactCell: isExporting }}
                component="badge"
                csvData={exportData}
              />
            </div>
          );
        },
      },
    ],
    [exportData, exportingId, handleAction, loadingResponse]
  );

  const handleRejectWorkflow = useCallback(async () => {
    await post(`operations/pandw/reject/${wfID}`);
    setRefresh(true);
  }, [wfID, refresh]);

  const handleSubmitWorkflow = useCallback(async () => {
    await post(`operations/pandw/submit/${wfID}`);
    setRefresh(true);
  }, [wfID, refresh]);

  const modals = useMemo(
    () => [
      {
        modalName: "reject-modal",
        isModalOpen: rejectModal,
        setIsModalOpen: (e) => updateState("rejectModal", e),
        content: "Are you sure you wish to reject this workflow?",
        buttonProps: [
          {
            name: "Cancel",
            color: "danger",
            onClick: () => updateState("rejectModal", !rejectModal),
          },
          {
            name: "Confirm",
            color: "primary",
            onClick: () => {
              handleRejectWorkflow();
              updateState("rejectModal", false);
            },
          },
        ],
      },
      {
        modalName: "submit-modal",
        isModalOpen: submitModal,
        setIsModalOpen: (e) => updateState("submitModal", e),
        content: "Are you sure you wish to submit this workflow?",
        buttonProps: [
          {
            name: "Cancel",
            color: "danger",
            onClick: () => updateState("submitModal", !submitModal),
          },
          {
            name: "Confirm",
            color: "primary",
            onClick: () => {
              updateState("confirmSubmitModal", !confirmSubmitModal);
              updateState("submitModal", false);
            },
          },
        ],
      },
      {
        modalName: "confirm-submit-modal",
        isModalOpen: confirmSubmitModal,
        setIsModalOpen: (e) => updateState("confirmSubmitModal", e),
        content: "Please confirm funds have been received and verified?",
        buttonProps: [
          {
            name: "Cancel",
            color: "danger",
            onClick: () =>
              updateState("confirmSubmitModal", !confirmSubmitModal),
          },
          {
            name: "Confirm",
            color: "primary",
            onClick: () => {
              handleSubmitWorkflow();
              updateState("confirmSubmitModal", false);
            },
          },
        ],
      },
      {
        modalName: "alert-modal",
        isModalOpen: alertModal,
        setIsModalOpen: (e) => updateState("alertModal", e),
        content:
          "Another Submit or Reject action is in process. Please wait before submitting another one.",
        buttonProps: [
          {
            name: "Ok",
            color: "danger",
            onClick: () => () => updateState("alertModal", !alertModal),
          },
        ],
      },
    ],
    [
      alertModal,
      confirmSubmitModal,
      handleRejectWorkflow,
      handleSubmitWorkflow,
      rejectModal,
      submitModal,
    ]
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <TableDashboard
      pageTitle="Purchases &amp; Withdrawals"
      setRefresh={setRefresh}
    >
      {modals?.map((modal) => {
        const { buttonProps, content, isModalOpen, setIsModalOpen, modalName } =
          modal || {};
        return (
          <div key={modalName}>
            <Modal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              content={content}
              modalButtons={buttonProps}
            />
          </div>
        );
      })}
      <Spinner requesting={isPending}>
        <Card className="pb-2">
          <CardBody className="py-2 bg-green">
            <PurchasesWithdrawalsSearch
              setSubmitSearch={setSubmitSearch}
              form={form}
            />
          </CardBody>
        </Card>
        <Table
          columns={pandwColumn}
          data={data}
          renderNestedTable={PandWWorkflowLogTable}
          pageProps={pageProps}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default PurchasesWithdrawals;
