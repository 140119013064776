import React from "react";

import { Typography, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import { CognitoFormAccountId } from "@config/conf";
import { getHostEnvironment } from "@utils/env";

import updateAllocations from "./NavigateToUpdateAllocationsPage";

const minimumWithdrawalAge = 59;
const hostDomain = getHostEnvironment();

const showTransferLink = () => {
  const terminationFlag = sessionStorage.getItem("terminationFlag");
  return terminationFlag?.toLowerCase() !== "false";
};

const showChangeCreditingOptionsLink = () => {
  const managedFlag = sessionStorage.getItem("managedFlag");
  return managedFlag?.toLowerCase() !== "false"; // ==='1'? false:true;
};

const getTransferLink = () => {
  const currentUrl = window.location.host.toLowerCase();

  const devStagingDocUrl =
    "https://www.cognitoforms.com/fps10/allianzirarolloverinkindstaging/";
  const prodDocUrl =
    "https://www.cognitoforms.com/Fps10/allianzirarolloverinkindproduction";

  const transferLink =
    currentUrl === "localhost:3001"
      ? devStagingDocUrl
      : currentUrl.startsWith("dev-annuitant-ui.retirement-edge.com")
        ? devStagingDocUrl
        : currentUrl.startsWith("stage-annuitant-ui.retirement-edge.com")
          ? devStagingDocUrl
          : prodDocUrl;

  return transferLink;
};

const Actions = (props) => {
  const { data } = props;

  const openCognitoForm = () => {
    const formId = hostDomain === "production" ? 4 : 3;
    const url = `https://www.cognitoforms.com/f/${CognitoFormAccountId}/${formId}?entry={"REAccountNumber":"${data?.reAccountNumber}"}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "allianz income activation";
    // link.target="_blank";
    // link.rel="noreferrer noopener"

    link.click();
  };

  const isParticipantEligibleToWithdraw = () => {
    const today = new Date();
    const dateFiftyNineYearsAgo = new Date();

    dateFiftyNineYearsAgo.setFullYear(
      today.getFullYear() - minimumWithdrawalAge
    );

    return new Date(data?.dob) <= dateFiftyNineYearsAgo;
  };

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid
          item
          xs={12}
          sm={1}
          style={{ paddingLeft: "10px", paddingTop: "1px", marginTop: "2px" }}
        >
          <Button
            style={{ display: showTransferLink() ? "block" : "none" }}
            href={getTransferLink()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-regular fa-money-bill-transfer fa-3x icon-blue" />
          </Button>

          <Button
            onClick={openCognitoForm}
            style={{
              display: isParticipantEligibleToWithdraw() ? "block" : "none",
              marginTop: "20px",
            }}
          >
            <i className="fa-solid fa-money-from-bracket fa-3x icon-blue" />
          </Button>
          <Button
            onClick={updateAllocations}
            style={{
              marginTop: "16px",
              display: showChangeCreditingOptionsLink() ? "none" : "block",
            }}
          >
            <i className="fa-solid fa-percent fa-3x icon-blue" />
          </Button>

          <Button style={{ marginTop: "16px" }}>
            <Link to="/View/Account/Documents">
              <i className="fa-solid fa-folder-open fa-3x icon-blue" />
            </Link>
          </Button>

          <Button style={{ marginTop: "16px", display: "none" }}>
            <i className="fa-solid fa-share-from-square fa-3x icon-blue" />
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sm={11}
          style={{ paddingLeft: "40px", border: "20px" }}
        >
          <a
            href={getTransferLink()}
            style={{
              color: "#494846",
              display: showTransferLink() ? "block" : "none",
            }}
          >
            <Typography variant="h6" component="div">
              Transfer
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "small" }}
              component="div"
            >
              You may transfer/rollover your Guaranteed Lifetime Income Policy
              out of the 401k plan
            </Typography>
          </a>
          <div
            style={{
              height: "1px",
              top: "5px",
              width: "90%",
              border: "1px solid lightgray",
              margin: "0px",
              display: showTransferLink() ? "block" : "none",
            }}
          />

          <div
            style={{
              display: isParticipantEligibleToWithdraw() ? "block" : "none",
            }}
          >
            <div
              style={{ color: "#494846", cursor: "pointer" }}
              onClick={openCognitoForm}
            >
              <Typography
                style={{ marginTop: "15px" }}
                variant="h6"
                component="div"
              >
                Request Income Activation
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontSize: "small" }}
                component="div"
              >
                Activate your Lifetime Income Withdrawal
              </Typography>
            </div>
            <div
              style={{
                height: "1px",
                top: "5px",
                width: "90%",
                border: "1px solid lightgray",
                margin: "0px",
              }}
            />
          </div>

          <a
            onClick={updateAllocations}
            href="#"
            style={{
              color: "#494846",
              display: showChangeCreditingOptionsLink() ? "none" : "block",
            }}
          >
            <Typography
              style={{ marginTop: "15px" }}
              variant="h6"
              component="div"
            >
              Change Crediting Options
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "small" }}
              component="div"
            >
              You may update crediting options in your annuity
            </Typography>
          </a>
          <div
            style={{
              height: "1px",
              top: "5px",
              width: "90%",
              border: "1px solid lightgray",
              margin: "0px",
              display: showChangeCreditingOptionsLink() ? "none" : "block",
            }}
          />

          <Link to="/View/Account/Documents" style={{ color: "#494846" }}>
            <>
              <Typography
                style={{ marginTop: "15px" }}
                variant="h6"
                component="div"
              >
                View Documents
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontSize: "small" }}
                component="div"
              >
                View documents
              </Typography>
            </>
          </Link>
          <div
            style={{
              height: "1px",
              top: "5px",
              width: "90%",
              border: "1px solid lightgray",
              margin: "0px",
            }}
          />

          <div style={{ display: "none" }}>
            <a href="#" style={{ color: "#494846" }}>
              <Typography
                style={{ marginTop: "15px" }}
                variant="h6"
                component="div"
              >
                Policy Separation
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontSize: "small" }}
                component="div"
              >
                Policy Separation
              </Typography>
            </a>
            <div
              style={{
                height: "1px",
                top: "5px",
                width: "90%",
                border: "1px solid lightgray",
                margin: "0px",
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Actions;
