import React from "react";

import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
  tg: {
    color: "#ffffff",
  },
  btn: {
    marginLeft: "auto",
    float: "right",
    color: "white",
    borderColor: "white",
  },
  abar: {
    fontSize: "10px",
    backgroundColor: "gray",
    innerHeight: "10px",
  },
}));

const Navbar = ({ userFirstName, invalidateAnnuitantToken }) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.abar}>
      <CssBaseline />
      <Toolbar>
        <Typography
          className={classes.tg}
          style={{ color: "white" }}
          variant="h6"
        >
          Welcome back, {userFirstName}
        </Typography>
        <Button
          endIcon={<ExitToAppIcon />}
          size="small"
          variant="outlined"
          onClick={invalidateAnnuitantToken}
          className={classes.btn}
        >
          LOGOUT
        </Button>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
