import React from "react";

import { Container } from "reactstrap";

import { sanitizeValue } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useToast from "@hooks/useToast";

import UserForm from "../UsersForm/UserForm";

const data = {
  userType: "Admin",
  userName: "",
  loginName: "",
  userEmail: "",
  userPhone: "",
  status: "Active",
  ipxHosted: false,
};

const AddUser = () => {
  const { post } = useApiFetch();
  const { validateRequiredField } = useToast();

  const handleAddUser = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { userName, loginName } = values || {};

    const isValid = validateRequiredField({
      "User Name": userName,
      "Login Name": loginName,
    });

    if (!isValid) {
      return;
    }
    post("users/create", values, { redirectUrl: "/users" });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">User Detail</h3>
      <UserForm data={data} redirectUrl="/users" onSubmit={handleAddUser} />
    </Container>
  );
};

export default AddUser;
