import { ResponsivePie } from "@nivo/pie";

const percentageFormat = (value) => `${value}%`;

const pieChartColorsMap = [
  "#A6CEE3",
  "#1F78B4",
  "#B2DF8A",
  "#33A02C",
  "#FB9A99",
  "#E31A1C",
  "#FDBF6F",
  "#FF7F00",
  "#CAB2D6",
  "#6A3D9A",
  "#FFFF99",
  "#B15928",
];

const AnnuityPieChart = ({ data = [], secondaryLegendData }) => (
  <div className="annuity_pie_chart">
    <div className="w-50 pie_wrapper">
      <ResponsivePie
        data={data}
        innerRadius={0.8}
        valueFormat={percentageFormat}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={{ scheme: "paired" }}
      />
      <div className="label-overlay">
        <h6>{data?.length}</h6>
        <small className="subtitle">Total</small>
        <small className="subtitle">Accounts</small>
      </div>
    </div>

    <div className="no-select chart_legends w-50">
      {data?.length
        ? data?.map(({ label, id }, index) => (
            <div key={id} className="legends_label">
              <span
                className="rounded-circle legend_icon"
                style={{ backgroundColor: pieChartColorsMap[index] }}
              />
              <p className="mt-0">{label}</p>
            </div>
          ))
        : null}
    </div>
  </div>
);

export default AnnuityPieChart;
