import React, { useState } from "react";

import { Grid, Typography, Button, Box } from "@material-ui/core";
import InfoOutlineIcon from "mdi-react/InfoOutlineIcon";
import { Tooltip } from "reactstrap";

import { docsPath } from "@config/url/documents";
import { formatCurrency } from "@containers/components/helpers";
import { getDocumentUrl } from "@utils/url";

import IncomeProjectionsDetails from "./IncomeProjectionsDetails";
import Actions from "../Actions";

const LifeTimeIncomeBenefitsDetails = ({ policyData, taxSource, data }) => {
  const { benefitBase } = policyData || {};
  const allianzProduct = getDocumentUrl(docsPath.allianzProductBrochure);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Grid item xs={6} sm={6}>
      <div className="border rounded">
        <div className="p-3 border rounded bg_color flex align-items-center justify-content-between">
          <div className="flex">
            <i className="fa fa-money-check-dollar fa-2x text-white" />
            <Typography
              variant="h6"
              className="text-white font-weight-normal ml-2"
            >
              Lifetime Income Benefit Details
            </Typography>
          </div>
          <Button
            href={allianzProduct}
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            className="px-2 bg-light text-dark text-transform-none"
          >
            How does it work?
          </Button>
        </div>
        <div className="flex justify-content-between align-items-center p-3">
          <div className="tooltip_wrapper">
            <p className="font-weight-bold pt-1">
              Current Lifetime Income Value
            </p>
            <InfoOutlineIcon id="lifeTimeIncome" size={15} />
            <Tooltip
              placement="bottom"
              isOpen={tooltipOpen}
              toggle={toggleTooltip}
              target="lifeTimeIncome"
            >
              Value as of the current date. The value used to determine your
              Projected Monthly Income uses the value as of your last
              anniversary as noted below.
            </Tooltip>
          </div>
          <Box
            variant="outlined"
            width={200}
            display="flex"
            justifyContent="end"
            p={1}
            className="border border-secondary font-weight-bold bg-inactive"
            borderRadius="borderRadius"
          >
            {formatCurrency(benefitBase)}
          </Box>
        </div>
        <div className="border-thin" />
        <IncomeProjectionsDetails policyData={policyData} />
      </div>
      <div className="mt-3 p-2 border rounded">
        <Typography variant="h6" className="mb-4">
          Actions
        </Typography>
        <Actions data={data.find((a) => a.taxCode === taxSource)} />
      </div>
    </Grid>
  );
};

export default LifeTimeIncomeBenefitsDetails;
