import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { Input, Select, Checkbox, FormFooterActions } from "@atoms";
import { msgs, statusOptions } from "@config/conf";
import {
  validateMaxValue,
  validateNumberField,
} from "@containers/components/helpers";
import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";

const { fieldError } = msgs || {};

const queryPath = ["from", "sortProductName", "sortCarrierName", "pageSize"];

const ProductFactorForm = (props) => {
  const {
    form,
    onSubmit,
    productID = "",
    productFactorID = "",
    loadingResponse,
  } = props || {};

  const history = useHistory();
  const { get, isPending } = useApiFetch();
  const [productData, setProductData] = useState({});
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: page, ...restParams } = getQueryParams(queryPath);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
  } = form || {};

  const isRequesting = loadingResponse || isPending;

  const getProduct = useCallback(async () => {
    const response = await get(`products/${productID}`);
    setProductData(response);
  }, [productID]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    if (productData?.productName) {
      setValue("productName", productData?.productName);
    }
  }, [productData?.productName]);

  const goBackUrl = useMemo(() => {
    const queryParams = buildQueryParams({ page, ...restParams });

    return `/products?${
      productFactorID ? `id=${productID}&` : ""
    }${queryParams}`;
  }, [page, productFactorID, restParams]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      history.push(goBackUrl);
    }
  }, [goBackUrl, isSubmitSuccessful]);

  const commonProps = useMemo(
    () => ({
      control,
      rules: {
        required: fieldError,
      },
    }),
    [control]
  );

  return (
    <Card className="product_factor_form_wrapper">
      <FormSpinner requesting={isRequesting} />
      <CardBody>
        <div className="form form--horizontal product_page_wrapper">
          <Input
            {...commonProps}
            name="productName"
            label="Product Name"
            required
            isReadOnly
          />
          <Input
            {...commonProps}
            name="factorName"
            label="Factor Name"
            required
          />
          <Checkbox {...commonProps} name="joint" label="Joint" required />
          <Input
            control={control}
            rules={{
              validate: (value) => validateMaxValue(value),
            }}
            name="jointPercent"
            label="Joint Percent"
            formatter={{ numericOnly: true }}
          />
          <Checkbox
            {...commonProps}
            name="refundable"
            required
            label="Refundable"
          />
          <Input
            control={control}
            rules={{
              required: fieldError,
              validate: (value) => validateNumberField(value, fieldError),
            }}
            name="deferralYears"
            label="Deferral Years"
            required
            formatter={{ numericOnly: true }}
          />
          <Checkbox control={control} name="selectable" label="Selectable" />
          <Select
            {...commonProps}
            name="status"
            label="Status"
            required
            options={statusOptions}
          />
          {productFactorID && (
            <Input
              control={control}
              name="lastUpdatedOn"
              label="Last Updated"
              isReadOnly
            />
          )}
          <FormFooterActions
            linkProps={[{ path: goBackUrl }]}
            buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ProductFactorForm;
