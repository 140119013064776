import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Badge } from "reactstrap";

import { TableDashboard, Spinner, Table } from "@atoms";
import { pagination } from "@config/conf";
import { getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";

import UsersTableSearch from "./components/UsersTableSearch";

const queryParams = [
  "pageSize",
  "search",
  "page",
  "sortUserType",
  "sortUserName",
  "sortStatus",
];

const Users = () => {
  const { get, isPending } = useApiFetch();

  const history = useHistory();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const {
    pageSize: pageSizePath,
    search,
    page,
    sortUserType,
    sortUserName,
    sortStatus,
  } = getQueryParams(queryParams);

  const form = useForm({
    defaultValues: {
      searchInput: search,
    },
  });

  const [refresh, setRefresh] = useState(false);
  const [submitSearch, setSubmitSearch] = useState(false);
  const [pageSize, setPageSize] = useState(pageSizePath || pagination.length);
  const [usersData, setUsersData] = useState({});

  const { offset, pageNumber } = getPagination(pageSize, page);
  const { data = [], recordCount = 0 } = usersData || {};

  const { getValues } = form;
  const { searchInput } = getValues();

  const orderBy = useMemo(() => {
    if (sortUserType)
      return `UserType ${sortUserType.toUpperCase()}, UserName ASC`;
    if (sortUserName) return `UserName ${sortUserName.toUpperCase()}`;
    if (sortStatus) return `Status ${sortStatus.toUpperCase()}, UserName ASC`;
    return "";
  }, [sortStatus, sortUserName, sortUserType]);

  const navigateToEditPage = useCallback(
    (id = "") => {
      const query = buildQueryParams({
        search: searchInput,
        sortUserType,
        sortUserName,
        sortStatus,
        pageSize,
      });
      const url = id ? `/${id}/edit?from=${pageNumber}` : "?page=1";
      return `/users${url}&${query}`;
    },
    [pageNumber, pageSize, searchInput, sortStatus, sortUserName, sortUserType]
  );

  const getUserData = useCallback(async () => {
    const params = buildQueryParams({
      Limit: pageSize,
      Offset: offset,
      OrderBy: orderBy,
      search: searchInput,
    });
    const response = await get(`users/get?${params}`);
    setUsersData(response);
  }, [offset, orderBy, pageSize, submitSearch]);

  useEffect(() => {
    if (submitSearch) {
      const pageReload = navigateToEditPage();
      history.push(pageReload);
    } else {
      getUserData();
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [getUserData, submitSearch, refresh]);

  const column = useMemo(
    () => [
      {
        id: "userType",
        header: "User Type",
        accessorFn: (row) => row?.userType || "-",
        enableSorting: true,
        size: 200,
      },
      {
        id: "userName",
        header: "User Name",
        accessorFn: (row) => row?.userName || "-",
        enableSorting: true,
        size: 250,
      },
      {
        id: "userPhone",
        header: "User Phone",
        accessorFn: (row) => row?.userPhone || "-",
      },
      {
        id: "userEmail",
        header: "User Email",
        size: 300,
        accessorFn: (row) => row?.userEmail || "-",
      },
      {
        id: "status",
        header: "Status",
        accessorFn: (row) => row?.status || "-",
        enableSorting: true,
      },
      {
        id: "editAction",
        header: "",
        cell: (tableRow) => {
          const { userID } = tableRow.row.original || {};
          const editUserPage = navigateToEditPage(userID);

          return (
            <div className="column-end pr-2 my-1">
              <Link to={editUserPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [navigateToEditPage]
  );

  const pageProps = useMemo(
    () => ({
      total: recordCount,
      pageSize,
      setPageSize,
      pageNumber,
    }),
    [pageNumber, pageSize, recordCount]
  );

  return (
    <TableDashboard
      pageTitle="Users"
      setRefresh={setRefresh}
      navigatePath="/users/add"
    >
      <Spinner requesting={isPending}>
        <UsersTableSearch form={form} setSubmitSearch={setSubmitSearch} />
        <Table columns={column} data={data} pageProps={pageProps} />
      </Spinner>
    </TableDashboard>
  );
};

export default Users;
