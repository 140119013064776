import React from "react";

const Spinner = ({ children, requesting = false }) =>
  requesting ? (
    <div className="pt-5">
      <h1 className="text-center text-primary">
        <span className="spinner-grow" />
      </h1>
    </div>
  ) : (
    <>{children}</>
  );

export default Spinner;
