import { Container } from "reactstrap";

import CustodiansForm from "../CustodiansForm/CustodiansForm";

const defaultData = {
  custodianName: "",
  custodianCode: "",
  bankName: "",
  routingNumber: "",
  accountNumber: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
};

const AddCustodian = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Custodian Detail</h3>
    <CustodiansForm data={defaultData} redirectUrl="/custodians" />
  </Container>
);

export default AddCustodian;
