import { useCallback, useEffect, useMemo, useState } from "react";

import { Card, CardBody } from "reactstrap";

import {
  Input,
  Select,
  Modal,
  FormFooterActions,
  DateRangePicker,
} from "@atoms";
import { msgs, statusOptions } from "@config/conf";
import { filterOptions } from "@containers/components/helpers";
import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate } from "@lib/date";

const queryParams = [
  "from",
  "search",
  "sortPlanName",
  "sortStatus",
  "pageSize",
];

const { fieldError } = msgs || {};

const validationMessage = (value) => {
  if (!value) {
    return fieldError;
  }
};

const PlanProductForm = (props) => {
  const { data, form, planProductId, onSubmit, planId, loadingResponse } =
    props || {};

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, isValid },
  } = form;

  const [modalOpen, setModalOpen] = useState(false);
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: pageNumber, ...restParams } = getQueryParams(queryParams);

  const { plansData, productsData } = data || {};

  const productOptions = filterOptions(
    productsData?.products?.data,
    "productID",
    "productName"
  );
  const isPlanIdentifierDirty =
    data?.carrierPlanCount > 1 && dirtyFields?.carrierPlanIdentifier && isValid;

  useEffect(() => {
    if (planProductId) {
      const { carrierProductCode, startDate } = data || {};
      reset({
        ...data,
        startDate: startDate ? formatDate(startDate) : null,
        productCode: carrierProductCode,
      });
    } else {
      reset({
        planName: plansData?.planName,
      });
    }
  }, [data?.planName, planProductId, plansData?.planName]);

  const renderCarrierPlanId = useMemo(
    () => (
      <Input
        name="carrierPlanIdentifier"
        control={control}
        label="Carrier Plan ID"
        maxLength={25}
        required
        rules={{
          required: fieldError,
          validate: (value) => validationMessage(value.trimStart()),
        }}
      />
    ),
    [control, fieldError]
  );

  const queryPathUrl = buildQueryParams({
    page: pageNumber,
    id: planProductId ? planId : "",
    ...restParams,
  });

  const handleFormSubmit = useCallback(() => {
    if (planProductId && isPlanIdentifierDirty) {
      setModalOpen(true);
    } else {
      handleSubmit(onSubmit)();
    }
  }, [onSubmit, planProductId, isPlanIdentifierDirty]);

  const modalButtons = useMemo(
    () => [
      {
        name: "Go Back",
        color: "danger",
        onClick: () => setModalOpen(!modalOpen),
      },
      {
        name: "Save",
        color: "primary",
        onClick: handleSubmit(onSubmit),
      },
    ],
    [modalOpen, onSubmit]
  );

  return (
    <Card className="container_width">
      <FormSpinner requesting={loadingResponse} />
      <CardBody className="form form--horizontal product_page_wrapper">
        <Modal
          isModalOpen={modalOpen}
          setIsModalOpen={setModalOpen}
          content="You’ve made a change to the Carrier Plan ID. This change will update the Carrier Plan ID for other Carrier Name products in this plan"
          modalButtons={modalButtons}
        />
        <Input
          name="planName"
          control={control}
          label="Plan Name"
          rules={{ required: fieldError }}
          isReadOnly
        />
        {planProductId ? (
          <>
            <Input
              name="productName"
              control={control}
              label="Product Name"
              isReadOnly
            />
            <Input
              name="carrierName"
              control={control}
              label="Carrier Name"
              isReadOnly
            />
            {renderCarrierPlanId}
            <Input
              name="productCode"
              control={control}
              label="Product Code"
              isReadOnly
            />
            <Input
              name="productIdentifier"
              control={control}
              label="Product Identifier"
              rules={{ required: fieldError }}
              isReadOnly
            />
            <DateRangePicker
              name="startDate"
              control={control}
              label="Start Date"
              rules={{ required: fieldError }}
            />
            <Select
              rules={{ required: fieldError }}
              control={control}
              name="status"
              label="Status"
              required
              options={statusOptions}
            />
          </>
        ) : (
          <>
            <Select
              name="productID"
              control={control}
              label="Product"
              required
              rules={{ required: fieldError }}
              placeholder="Select Product"
              options={productOptions}
              isLoading={productsData?.requesting}
            />
            {renderCarrierPlanId}
          </>
        )}
        <FormFooterActions
          linkProps={[{ path: `/plans?${queryPathUrl}` }]}
          buttonProps={[{ onClick: handleFormSubmit }]}
        />
      </CardBody>
    </Card>
  );
};

export default PlanProductForm;
