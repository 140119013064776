import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Table } from "reactstrap";

import { getRKChannels } from "@redux/actions/rkChannelsActions";

// const { tableRowsData } = RKChannelsTableData();

const RKChannelsTable = () => {
  const dispatch = useDispatch();
  const rkChannels = useSelector((state) => state.rkChannels);

  useEffect(() => {
    dispatch(getRKChannels());
  }, [dispatch]);

  if (rkChannels.requesting) {
    return (
      <div>
        <h1 className="text-center text-primary">
          <span className="spinner-grow">*</span>
        </h1>
      </div>
    );
  }

  if (rkChannels.err) {
    return (
      <div>
        <p className="text-center text-danger">! {rkChannels.err}</p>
      </div>
    );
  }

  return (
    // <Col md={12} lg={12}>
    <Card>
      <CardBody>
        {/* <div className="card__title">
            <h5 className="bold-text">{t('tables.basic_tables.responsive_table')}</h5>
            <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5>
          </div> */}
        <Table responsive className="table--bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>RK Channel Name</th>
              <th>Accept New Record Keeper</th>
              <th>Accept New Plans</th>
              <th>Active</th>
              <th>Action</th>
              {/* {tableHeaderResponsiveData.map(item => (
                <th key={item.id}>{item.title}</th>
              ))} */}
            </tr>
          </thead>
          <tbody>
            {rkChannels.rkChannels.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.rkChannelName}</td>
                <td>{item.acceptNewRecordKeepers ? "Yes" : "No"}</td>
                <td>{item.acceptNewPlans ? "Yes" : "No"}</td>
                <td>{item.active ? "Yes" : "No"}</td>
                {/* <td><Badge color={item.status_resp}>{item.badge_resp}</Badge></td> */}
                <td>
                  <Link to={`/rk_channels/${item.id}/edit`}>
                    <Badge color="primary">View / Edit</Badge>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
    // </Col>
  );
};

// ResponsiveTable.propTypes = {
//   tableType: PropTypes.string.isRequired,
// };

export default RKChannelsTable;
