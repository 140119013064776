import React, { useEffect, useState, useMemo, useCallback } from "react";

import { Link } from "react-router-dom";
import { Badge, Card } from "reactstrap";

import { Spinner, Table } from "@atoms";
import { pagination } from "@config/conf";
import { getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate } from "@lib/date";

const queryPath = ["pageSize", "sortCarrierName", "sortProductName", "page"];

const ProductsTable = ({ refresh, setRefresh }) => {
  const { get, isPending } = useApiFetch();
  const [fetchData, setFetchData] = useState({});
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const {
    pageSize: queryPageSize,
    sortCarrierName,
    sortProductName,
    page,
  } = getQueryParams(queryPath);

  const [pageSize, setPageSize] = useState(queryPageSize || pagination.length);
  const { offset, pageNumber } = getPagination(pageSize, page);
  const { data: productsData = [], recordCount = 0 } = fetchData || {};

  const navigatePath = useCallback(
    (id = "", childParams = "") => {
      const query = buildQueryParams({
        from: pageNumber,
        sortProductName,
        sortCarrierName,
        pageSize,
      });
      return `/products/${id}/${childParams}?${query}`;
    },
    [pageNumber, pageSize, sortCarrierName, sortProductName]
  );

  const orderBy = useMemo(() => {
    if (sortCarrierName)
      return `Carriers.CarrierName ${sortCarrierName.toUpperCase()}, Products.ProductName ASC, ProductInvOptions.PIOName ASC`;
    if (sortProductName)
      return `Products.ProductName ${sortProductName.toUpperCase()}, ProductInvOptions.PIOName ASC`;
    return "";
  }, [sortCarrierName, sortProductName]);

  const getData = useCallback(async () => {
    const response = await get(
      `products-and-pios/get?limit=${pageSize}&offset=${offset}${
        orderBy ? `&OrderBy=${orderBy}` : ""
      }`
    );
    setFetchData(response);
  }, [offset, pageSize, orderBy]);

  useEffect(() => {
    getData();
    setRefresh(false);
  }, [getData, refresh]);

  const columns = useMemo(
    () => [
      {
        id: "expanded-chevron",
        header: "",
        size: 0,
        cell: (tableRow) => {
          const { expandedRowIds, row } = tableRow || {};
          const className = `lnr ${
            expandedRowIds?.includes(row.id)
              ? "lnr-chevron-down"
              : "lnr-chevron-right"
          }`;

          return (
            <div className="expanded_chevron">
              <i className={className} />
            </div>
          );
        },
      },
      {
        id: "productName",
        header: "Product Name",
        accessorKey: "productName",
        enableSorting: true,
      },
      {
        id: "carrierName",
        header: "Carrier",
        accessorKey: "carrierName",
        enableSorting: true,
      },
      {
        id: "carrierProductCode",
        header: "Product Code",
        accessorKey: "carrierProductCode",
      },
      {
        id: "actions",
        header: "",
        cell: (tableRow) => {
          const { productID, productType } = tableRow?.row?.original || {};
          const isIAProduct = productType === "Immediate Annuity";
          const gotoEditProductPage = navigatePath(productID, "edit");
          const gotoAddProductPage = navigatePath(productID, "pio/add");
          const gotoWRPage = navigatePath(productID, "wr");
          const gotoAddFactorsPage = navigatePath(productID, "factor/add");
          return (
            <div className="column-end pr-2 my-1">
              <Link className="mr-2" to={gotoEditProductPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
              {isIAProduct ? (
                <Link to={gotoAddFactorsPage}>
                  <Badge color="success">Add Factor</Badge>
                </Link>
              ) : (
                <>
                  <Link className="mr-2" to={gotoAddProductPage}>
                    <Badge color="success">Add PIO</Badge>
                  </Link>
                  <Link to={gotoWRPage}>
                    <Badge color="success">WR</Badge>
                  </Link>
                </>
              )}
            </div>
          );
        },
      },
    ],
    [navigatePath]
  );

  const piosColumns = useCallback(
    (productId) => [
      {
        id: "pioName",
        header: "PIO Name",
        accessorKey: "pioName",
      },
      {
        id: "carrierPIOCode",
        header: "PIO Code",
        accessorKey: "carrierPIOCode",
      },
      {
        id: "pioSelectable",
        header: "Selectable",
        accessorKey: "pioSelectable",
      },
      {
        id: "pioStatus",
        header: "Status",
        accessorKey: "pioStatus",
      },
      {
        id: "pioLastUpdatedOn",
        header: "Last Updated",
        accessorFn: (row) => formatDate(row?.pioLastUpdatedOn, "MM/DD/YY"),
      },
      {
        id: "edit-view button",
        header: "",
        cell: (tableRow) => {
          const { pioid } = tableRow?.row?.original || {};
          const editPioDetails = navigatePath(productId, `pio/${pioid}/edit`);
          return (
            <div className="column-end pr-2">
              <Link to={editPioDetails}>
                <Badge className="btn-blue">View / Edit</Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [navigatePath]
  );

  const factorsColumns = useCallback(
    (productId) => [
      {
        id: "factorName",
        header: "Factors Name",
        accessorKey: "factorName",
      },
      {
        id: "joint",
        header: "Joint",
        accessorKey: "joint",
      },
      {
        id: "jointPercent",
        header: "Joint Percent",
        accessorKey: "jointPercent",
      },
      {
        id: "refundable",
        header: "Refundable",
        accessorKey: "refundable",
      },
      {
        id: "deferralYears",
        header: "Deferral years",
        accessorKey: "deferralYears",
      },
      {
        id: "selectable",
        header: "Selectable",
        accessorKey: "selectable",
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
      },
      {
        id: "edit-view button",
        header: "",
        cell: (tableRow) => {
          const { productFactorID } = tableRow?.row?.original || {};
          const editFactorsDetails = navigatePath(
            productId,
            `factor/${productFactorID}/edit`
          );
          return (
            <div className="column-end pr-2">
              <Link to={editFactorsDetails}>
                <Badge className="btn-blue">View / Edit</Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [navigatePath]
  );

  const renderSubTable = useCallback(
    (subTableProps) => {
      const {
        productType,
        pios = [],
        productFactors = [],
        productID,
      } = subTableProps.row.original || {};

      const isIAProduct = productType === "Immediate Annuity";

      const factorColumn = factorsColumns(productID);
      const pioColumn = piosColumns(productID);

      return (
        <Table
          columns={isIAProduct ? factorColumn : pioColumn}
          data={isIAProduct ? productFactors : pios}
          hasNestedTable
        />
      );
    },
    [factorsColumns, piosColumns]
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <Spinner requesting={isPending}>
      <Card>
        <Table
          data={productsData}
          columns={columns}
          renderNestedTable={renderSubTable}
          pageProps={pageProps}
        />
      </Card>
    </Spinner>
  );
};

export default ProductsTable;
