import React, { useState, useEffect } from "react";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Container, Row, Col } from "reactstrap";

import useApiFetch from "@hooks/useApiFetch";

const targetHost = window.location.host.toLowerCase();

export const AuthorizeExternal = () => {
  const { post } = useApiFetch();
  const { user } = useAuth0();
  const [loginResponse, setLoginResponse] = useState("");

  const {
    REAccountNumber,
    ManagedFlag = false,
    TerminationFlag = false,
    myAppMetadata,
    ClientID,
  } = user || {};

  const handleLogin = async () => {
    const credentials = {
      loginName: "GenericAnnuitantUser",
      password: "fgLGvAuNo$U!n@W2(XhF",
      reAccountNumber: REAccountNumber,
    };

    const response = await post("loginGenericAnnuitantUser", credentials, {
      loginAuth: true,
      successToast: false,
    });
    setLoginResponse(response?.data?.token);
  };

  useEffect(() => {
    handleLogin();
  }, []);

  useEffect(() => {
    if (loginResponse) {
      sessionStorage.setItem("reAccountNumber", REAccountNumber);
      sessionStorage.setItem("managedFlag", ManagedFlag);
      sessionStorage.setItem("terminationFlag", TerminationFlag);

      /// /https://happy-grass-0e0eb9f10.1.azurestaticapps.net/
      window.location.replace(
        `${window.location.protocol}//${targetHost}/View/GenericUserAccount/Summary/${REAccountNumber}?token=${loginResponse}`
      );
    }
  }, [ManagedFlag, REAccountNumber, TerminationFlag, loginResponse]);

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        {/* <Col md={2}>
              <img
                src={user.picture}
                alt="Profile"
                className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
              />
            </Col> */}
        <Col md>
          {/* <h6>My App Metadata</h6> */}
          <p className="lead text-muted">My App Metadata: {myAppMetadata}</p>
        </Col>
      </Row>

      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          {/* <h2>{user.ClientID}</h2> */}
          <p className="lead text-muted">Client ID: {ClientID}</p>
        </Col>
      </Row>

      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          {/* <h2>{user.ManagedFlag}</h2> */}
          <p className="lead text-muted">Managed Flag: {ManagedFlag}</p>
        </Col>
      </Row>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          {/* <h2>{user.REAccountNumber}</h2> */}
          <p className="lead text-muted">Account Number: {REAccountNumber}</p>
        </Col>
      </Row>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          {/* <h2>{user.TerminationFlag}</h2> */}
          <p className="lead text-muted">Termination Flag: {TerminationFlag}</p>
        </Col>
      </Row>

      {/*
          <Row>
            {user["idpAccountNumberPassed"]}
          </Row>
          */}
    </Container>
  );
};

export default withAuthenticationRequired(AuthorizeExternal, {
  onRedirecting: () => (
    <>
      <span className="spinner-border spinner-border-sm" />
    </>
  ),
});
