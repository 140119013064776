import { useSnackbar } from "notistack";

import { msgs } from "@config/conf";

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessToast = (msg, toastProps) => {
    const toastMessage = msg || msgs.success;
    enqueueSnackbar(toastMessage, {
      variant: "success",
      autoHideDuration: 3000,
      ...toastProps,
    });
  };

  const showErrorToast = (msg, toastProps) => {
    const toastMessage = msg || msgs.error;
    enqueueSnackbar(toastMessage, {
      variant: "error",
      autoHideDuration: 5000,
      preventDuplicate: true,
      ...toastProps,
    });
  };

  const validateRequiredField = (fields) => {
    const keyValues = Object.keys(fields);
    let isValid = true;

    keyValues.forEach((fieldName) => {
      if (!fields[fieldName]) {
        showErrorToast(`${fieldName} must not be empty`, {
          transitionDuration: { appear: 5000, enter: 1000, exit: 1000 },
        });
        isValid = false;
      }
    });
    return isValid;
  };

  return {
    showSuccessToast,
    showErrorToast,
    validateRequiredField,
  };
};

export default useToast;
