import React from "react";

import { Link } from "react-router-dom";

const TopbarMenuLinks = ({ title, icon, path, onClick }) => (
  <Link className="topbar__link" to={path} onClick={onClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </Link>
);

export default TopbarMenuLinks;
